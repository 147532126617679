import {
  RouteProp,
  useIsFocused,
  useLinkProps,
  useRoute,
} from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Image, Platform, ScrollView, View } from 'react-native';
import {
  ActivityIndicator,
  Appbar,
  Button,
  Portal,
  Text,
  useTheme,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useGoBack } from '../components/BackHeader';
import { PressableSurface } from '../components/PressableSurface';
import { useEvent } from '../events/useEvent';
import { defineTranslations, i18n } from '../locale';
import { prepareUrl } from '../navigation/LinkingConfiguration';
import { BACKGROUND_DARK, PRIMARY_DARK } from '../theming';
import { RootStackParamList } from '../types';
import { useThemedColor } from '../utils/useThemedColor';
import { IMAGE_PIXEL_RATIO, variantImageUrl } from '../utils/variants';
import { ApiNominalRanking, useEventRanking } from './useEventRanking';

defineTranslations({
  en: {
    app: {
      ranking: {
        title: 'Ranking',
        description:
          'Aid your favouriete artist during the competition of the Mentos Grote Prijs van Nederland 2023. This ranking is updated every fifteen minutes.',

        share_dialog_title: 'Share this track',
        share_message:
          'I am interested in your opinion about {{title}}! {{url}}',

        details_title: 'Biography {{name}}',

        votes_count: '{{value}} votes',
        score: 'Score {{value}}',

        actions: {
          vote: 'Vote',
          share: 'Share',
          close_details: 'Close details',
        },
      },
    },
  },

  nl: {
    app: {
      ranking: {
        title: 'Ranking',
        description:
          'Help je favoriete artiest mee te doen aan de Mentos Grote Prijs van Nederland 2023. Deze ranking wordt elk kwartier bijgewerkt.',

        share_dialog_title: 'Deel deze track',
        share_message: 'Ik ben benieuwd wat je van {{title}} vindt! {{url}}',

        details_title: 'Biografie {{name}}',

        votes_count: '{{value}} stemmen',
        score: 'Score {{value}}',

        actions: {
          vote: 'Stem',
          share: 'Delen',
          close_details: 'Sluit details',
        },
      },
    },
  },
});

export function RankingScreen() {
  const { top, bottom } = useSafeAreaInsets();

  const focused = useIsFocused();
  const { url } =
    useRoute<RouteProp<RootStackParamList, 'Ranking'>>().params ?? {};

  const { data: eventData, error: eventError } = useEvent(url, {
    enabled: focused,
  });
  const { data, error, isLoading, isFetched } = useEventRanking(
    url + '/rankings/nominal',
    'nominal',
    {
      enabled: focused,
      staleTime: 600,
    }
  );

  const timestamp = useInaccurateTimestamp({ every: 30 * 1000 });
  const started = Boolean(
    eventData?.event.start_at &&
      new Date(timestamp).getTime() >
        new Date(eventData?.event.start_at).getTime()
  );
  const finished = Boolean(
    eventData?.event.end_at &&
      new Date(timestamp).getTime() >
        new Date(eventData?.event.end_at).getTime()
  );

  const eventHref = eventData?.event._links.self.href || '';
  const open = started && !finished;
  const linkProps = useLinkProps({
    to: [`/events/${prepareUrl(eventHref)}`].filter(Boolean).join('?'),
  });

  const onGoBack = useGoBack();
  const textColor = useThemedColor('#222', '#DDD');
  const rankColor = useThemedColor('#000', '#FFF');
  const surfaceColor = useThemedColor('#FFF', BACKGROUND_DARK);

  return (
    <Portal.Host>
      <View style={{ flex: 1, position: 'relative', width: '100%' }}>
        <ScrollView
          style={{
            width: '100%',
            height: '100%',
            maxHeight: Platform.select({ web: '100vh', default: '100%' }),
          }}
          contentContainerStyle={{
            width: '100%',
            minHeight: '100%',
            alignSelf: 'center',
            overflow: 'hidden',
            paddingTop: top,
            paddingBottom: bottom,
          }}
        >
          <LinearGradient
            colors={['#5759FB', '#EF7B90', '#f19167' /*'#F5C111'*/]}
            locations={[0.3, 0.8, 1]}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
          <View
            style={{
              marginHorizontal: 'auto',
              width: '100%',
              maxWidth: 600,
              flex: 1,
              paddingTop: 90,
              paddingBottom: 32,
            }}
          >
            {isLoading || !data ? (
              <ActivityIndicator
                size="large"
                color="#FFF"
                style={{ marginTop: 64 }}
              />
            ) : (
              <NominalRanking
                ranking={data.ranking}
                eventHref={eventHref}
                textColor={textColor}
                rankColor={rankColor}
                surfaceColor={surfaceColor}
              />
            )}
          </View>
        </ScrollView>
        <View
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              maxWidth: 600,
              marginHorizontal: 'auto',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingHorizontal: 36,
              paddingTop: top + 12,
            }}
          >
            <Appbar.BackAction
              size={24}
              color="#fff"
              onPress={onGoBack}
              style={{
                borderRadius: 54 / 2,
                backgroundColor: `${PRIMARY_DARK}40`,
                width: 42,
                height: 42,
                marginLeft: -2,
              }}
            />
            <Button
              mode="contained"
              buttonColor="#F5C100"
              textColor="#000"
              disabled={!open}
              labelStyle={{ fontWeight: '500' }}
              contentStyle={{ flexDirection: 'row-reverse', height: 44 }}
              icon="arrow-right"
              {...(open ? linkProps : undefined)}
            >
              {i18n.translate('app.ranking.actions.vote')}
            </Button>
          </View>
        </View>
      </View>
    </Portal.Host>
  );
}

function NominalRanking({
  ranking,
  eventHref,
  textColor,
  rankColor,
  surfaceColor,
}: ApiNominalRanking & {
  eventHref: string;
  textColor: string;
  rankColor: string;
  surfaceColor: string;
}) {
  return (
    <View style={{ paddingHorizontal: 32 }}>
      <Text
        variant="titleLarge"
        style={{ color: 'white', fontSize: 32, lineHeight: 48 }}
      >
        {i18n.translate('app.ranking.title')}
      </Text>
      <Text
        variant="bodyLarge"
        style={{
          color: 'white',
          marginTop: 8,
          fontWeight: '300',
          marginBottom: 64,
        }}
      >
        {i18n.translate('app.ranking.description')}
      </Text>

      {ranking._embedded.map((entry) => {
        return (
          <RankingItem
            key={entry._links.self.href}
            entry={entry}
            eventHref={eventHref}
            textColor={textColor}
            rankColor={rankColor}
            surfaceColor={surfaceColor}
          />
        );
      })}
    </View>
  );
}

function RankingItem({
  entry,
  eventHref,
  textColor,
  rankColor,
  surfaceColor,
}: {
  entry: ApiNominalRanking['ranking']['_embedded'][number];
  eventHref: string;
  textColor: string;
  rankColor: string;
  surfaceColor: string;
}) {
  const uri = variantImageUrl(entry._links.cover?.href, 'thumbnail');
  const linkProps = useLinkProps({
    to: [
      `/events/${prepareUrl(eventHref)}/ranking/${prepareUrl(
        entry._links.self.href
      )}`,
    ]
      .filter(Boolean)
      .join('?'),
  });

  const winner =
    entry.ranked &&
    entry.score &&
    entry.rank &&
    eventHref.includes('f541f73d-7b66-4d72-99ec-77a52364ca4c') &&
    entry.rank < 4;

  return (
    <PressableSurface
      style={{
        borderRadius: 8,
        marginTop: 8,
        elevation: 2,
        backgroundColor: surfaceColor,
      }}
      linkProps={eventHref ? linkProps : undefined}
    >
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 8,
        }}
      >
        <View style={{ width: 56, justifyContent: 'center' }}>
          <Text
            style={{
              color: rankColor,
              fontWeight: '600',
              fontSize: 24,
              textAlign: 'center',
            }}
          >
            {entry.ranked ? entry.rank : '-'}
          </Text>
          {winner ? (
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                width: 56,
              }}
            >
              <Image
                source={require('../../assets/winner.png')}
                style={{ width: 14, height: 14 }}
                resizeMode="contain"
              />
            </View>
          ) : null}
        </View>
        <View style={{ width: 44, marginRight: 12 }}>
          <Image
            source={{
              uri,
              width: IMAGE_PIXEL_RATIO,
              height: IMAGE_PIXEL_RATIO,
            }}
            style={{
              width: 44,
              height: 44,
              borderRadius: 4,
              backgroundColor: '#222',
            }}
          />
        </View>

        <View style={{ justifyContent: 'center', paddingRight: 12, flex: 1 }}>
          <Text
            variant="bodySmall"
            style={{ color: textColor, fontWeight: '300', fontSize: 14 }}
            numberOfLines={1}
          >
            {entry._embedded.artist.name}
          </Text>
          <Text
            variant="bodySmall"
            style={{ color: textColor, fontWeight: 'bold', fontSize: 14 }}
            numberOfLines={1}
          >
            {entry.title}
          </Text>
        </View>
      </View>
    </PressableSurface>
  );
}
