import { RouteProp, useLinkTo, useRoute } from '@react-navigation/native';
import { AnimatePresence } from 'moti';
import React, {
  Component,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FlatList,
  KeyboardAvoidingView,
  ListRenderItemInfo,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  HelperText,
  List,
  Menu,
  Portal,
  Text,
  TextInput,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SvgUri } from 'react-native-svg';
import { useIsMounted } from 'use-is-mounted';
import { useUpdateProfile } from '../account/useUserProfile';
import { PrimaryButton } from '../components/Button';
import { Presence } from '../components/Presence';
import { ScreenReaderHidden } from '../components/ScreenReaderHidden';
import { useMatchMedia } from '../hooks/useMatchMedia';
import { defineTranslations, i18n } from '../locale';
import { resetToPath } from '../navigation/utils';
import { ProfilerScreen } from '../sampling/ProfilerScreen';
import {
  DARK_PURPLE,
  PASTEL_PINK,
  PASTEL_PURPLE,
  PRIMARY_LIGHT,
} from '../theming';
import { TutorialScreen } from '../tutorial/TutorialScreen';
import { RootStackParamList } from '../types';
import { useForceUpdate } from '../utils/useForceUpdate';
import { useModalState } from '../utils/useModalState';
import { useThemedColor } from '../utils/useThemedColor';
import { BornAtField } from './BornAtField';
import { FormWrapper } from './FormWrapper';
import {
  SoundersProfileTagsCollection,
  usePublicProfileTags,
  useUserProfileTags,
} from './useProfileTags';
import {
  SoundersCountriesCollection,
  SoundersCountry,
  usePublicCountries,
} from './usePublicCountries';

defineTranslations({
  en: {
    app: {
      preferences: {
        title: 'Before we start',
        description:
          'Please tell us about yourself. Artists are interested in statistics. We will never share or sell your personal information, but we aggregate them to display in an overview.',
        actions: {
          next: {
            label: 'Continue',
          },
        },
      },
      preferences_done: {
        title: 'You Rock!',
        description: 'Now let’s find out what music you like!',
      },

      fields: {
        display_name: {
          label: 'Display name',
          helper_text:
            'We use this when mentioning you to others, or displaying your name for example when you share a track.',
        },
        gender: {
          label: 'Gender',
          helper_text:
            'We like to show statistics and demographics of the audience to artists and researchers. We are advocating for a broader range of options in the future.',

          options: {
            null: 'Select an option',
            male: 'Male',
            female: 'Female',
            other: 'Other',
            none: 'Prefer not to disclose',
          },
        },
        born_at: {
          label: 'Born at',
          helper_text:
            'We use this to determine your approximate age at the moment of a vote. Your exact age or birthday is never shown to anyone without your explicit permission.',
        },
        country: {
          label: 'Country (primary residence)',
          helper_text:
            'We use this to display to artists where feedback originated without having to inspect personal information such as an IP address.',

          options: {
            null: 'Select an option',
          },

          title: 'Where do you live?',
        },
        tags: {
          label: 'Indicate who or what you are',
          helper_text:
            'We use this to display to artists if feedback came from a music lover, or someone who works in the industry.',

          options: {
            null: 'Select all that apply',
            length: '{{length}} selected',
            value: '{{tag}} and {{length}} more',
          },
        },
      },
    },
  },

  nl: {
    app: {
      preferences: {
        title: 'Nog even vooraf',
        description:
          'Kan je ons iets voor jezelf vertellen? Artiesten zijn geïntereseerd in statistieken. Wij delen of verkopen nooit jouw persoonlijke gegevens, maar tellen ze op voor een totaal plaatje.',
        actions: {
          next: {
            label: 'Doorgaan',
          },
        },
      },
      preferences_done: {
        title: 'Bedankt!',
        description: 'Laten we nu kijken wat jouw muzieksmaak is!',
      },
      fields: {
        display_name: {
          label: 'Schermnaam',
          helper_text:
            'We gebruiken dit als we jouw naam tonen aan anderen, of bijvoorbeeld als je een track met iemand deelt.',
        },
        gender: {
          label: 'Gender',
          helper_text:
            'We willen graag statistiek toepassen en demografie van de luisteraar laten zien aan artiesten en onderzoekers. We zijn in onderhandeling met die groepen om meer opties te tonen in de toekomst.',

          options: {
            null: 'Maak een keuze',
            male: 'Man',
            female: 'Vrouw',
            other: 'Anders',
            none: 'Zeg ik liever niet',
          },
        },
        born_at: {
          label: 'Geboortedatum',
          helper_text:
            'We gebruiken dit om je leeftijdsgroep te bepalen op het moment dat je stemt. Je exacte verjaardag of leeftijd wordt nooit zonder jouw toestemming weergegeven of gedeeld.',
        },
        country: {
          label: 'Land (woon adres)',
          helper_text:
            'We gebruiken dit om artiesten te laten zien waar feedback vandaan komt, zonder dat we daar persoonlijke informatie zoals je IP adres voor hoeven te gebruiken.',

          options: {
            null: 'Maak een keuze',
          },

          title: 'Waar woon je?',
        },
        tags: {
          label: 'Geef aan wie of wat je bent',
          helper_text:
            'We gebruiken dit om artiesten te laten zien of feedback is gegeven door een muziekliefhebber, of door iemand die werkzaam is in de industrie.',

          options: {
            null: 'Selecteer alles van toepassing',
            length: '{{length}} geselecteerd',
            value: '{{tag}} en nog {{length}}',
          },
        },
      },
    },
  },
});

function PreferencesTitle() {
  const color = useThemedColor(DARK_PURPLE, '#FFF');

  return (
    <Text
      style={{
        maxWidth: 300,
        marginHorizontal: 38,
        includeFontPadding: false,
        color,
        display: 'flex',
      }}
      variant="displayMedium"
    >
      {i18n.translate('app.preferences.title')}&hellip;
    </Text>
  );
}

function PreferencesDoneTitle() {
  const color = useThemedColor(DARK_PURPLE, '#FFF');

  return (
    <Text
      style={{
        maxWidth: 300,
        marginHorizontal: 38,
        includeFontPadding: false,
        color,
        display: 'flex',
      }}
      variant="displayMedium"
    >
      {i18n.translate('app.preferences_done.title')}
    </Text>
  );
}

function PreferencesDescription() {
  const color = useThemedColor(DARK_PURPLE, PASTEL_PINK);

  return (
    <Text
      style={{
        color,
        marginHorizontal: 38,
        maxWidth: 400,
        includeFontPadding: false,
        marginTop: 12,
        display: 'flex',
      }}
      variant="bodyLarge"
    >
      {i18n.translate('app.preferences.description')}
    </Text>
  );
}

function PreferencesDoneDescription() {
  const color = useThemedColor(DARK_PURPLE, PASTEL_PINK);

  return (
    <Text
      style={{
        color,
        marginHorizontal: 38,
        maxWidth: 400,
        includeFontPadding: false,
        marginTop: 12,
        display: 'flex',
      }}
      variant="bodyLarge"
    >
      {i18n.translate('app.preferences_done.description')}
    </Text>
  );
}
function PreferencesDoneWhatsNext() {
  const color = useThemedColor(DARK_PURPLE, PASTEL_PINK);

  return (
    <Text
      style={{
        color,
        marginHorizontal: 38,
        maxWidth: 400,
        includeFontPadding: false,
        marginTop: 12,
        display: 'flex',
      }}
      variant="bodyLarge"
    >
      Rate a few songs to complete your personal music taste profile.
    </Text>
  );
}

function NameInput(props: React.ComponentProps<typeof TextInput>) {
  const color = useThemedColor(PASTEL_PURPLE, PASTEL_PINK);
  const textColor = useThemedColor(DARK_PURPLE, '#FFF');

  return (
    <View
      style={{
        position: 'relative',
        width: '100%',
        marginTop: 24,
      }}
    >
      <TextInput
        mode="flat"
        style={{
          backgroundColor: 'transparent',
          paddingHorizontal: 0,
          textAlignVertical: 'center',
          includeFontPadding: false,
        }}
        label={i18n.translate('app.fields.display_name.label')}
        numberOfLines={1}
        keyboardType="default"
        returnKeyType="next"
        autoCapitalize="words"
        autoCorrect={false}
        autoComplete="name"
        {...Platform.select({
          android: {},
          ios: {
            textContentType: 'nickname',
            autoComplete: 'nickname' as string as 'name',
          },
          default: {},
        })}
        {...props}
        theme={{ colors: { primary: color } }}
        textColor={textColor}
        accessibilityLabelledBy="display-name-description"
      />
      <ScreenReaderHidden
        webStyle={{ marginTop: 8 }}
        nativeStyle={{ marginTop: 8 }}
      >
        <HelperText
          nativeID="display-name-description"
          type="info"
          style={{ paddingHorizontal: 0 }}
        >
          {i18n.translate('app.fields.display_name.helper_text')}
        </HelperText>
      </ScreenReaderHidden>
    </View>
  );
}

function GenderInput({
  displayValue,
  onChangeValue,
}: {
  displayValue: string;
  onChangeValue(next: string | null): void;
}) {
  const {
    showing: selectingGender,
    show: startSelectingGender,
    dismiss: stopSelectingGender,
    finish: selectGender,
  } = useModalState(onChangeValue);

  const { top, bottom } = useSafeAreaInsets();

  return (
    <View
      style={{
        position: 'relative',
        width: '100%',
        marginTop: 24,
        paddingVertical: 8,
      }}
    >
      <ScreenReaderHidden>
        <Text variant="labelSmall" style={{ display: 'flex' }}>
          {i18n.translate('app.fields.gender.label')}
        </Text>
      </ScreenReaderHidden>
      <Menu
        anchor={
          <Button
            onPress={startSelectingGender}
            mode="contained-tonal"
            uppercase={false}
            accessibilityLabel={i18n.translate('app.fields.gender.label')}
            accessibilityHint={i18n.translate('app.fields.gender.helper_text')}
            accessibilityLanguage={i18n.locale}
            labelStyle={{
              textAlign: 'left',
              marginLeft: 12,
              marginRight: 'auto',
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            style={{ borderRadius: 8, marginTop: 4 }}
          >
            {displayValue}
          </Button>
        }
        visible={selectingGender}
        onDismiss={stopSelectingGender}
        style={{
          marginTop: top,
          marginBottom: bottom,
          maxWidth: Platform.select({ web: '80vw', default: '80%' }),
        }}
      >
        <Menu.Item
          title={i18n.translate('app.fields.gender.options.male')}
          titleStyle={{
            textAlignVertical: 'center',
            includeFontPadding: false,
          }}
          onPress={() => selectGender('male')}
        />
        <Menu.Item
          title={i18n.translate('app.fields.gender.options.female')}
          titleStyle={{
            textAlignVertical: 'center',
            includeFontPadding: false,
          }}
          onPress={() => selectGender('female')}
        />
        <Menu.Item
          title={i18n.translate('app.fields.gender.options.other')}
          titleStyle={{
            textAlignVertical: 'center',
            includeFontPadding: false,
          }}
          onPress={() => selectGender('other')}
        />
        <Menu.Item
          title={i18n.translate('app.fields.gender.options.none')}
          titleStyle={{
            textAlignVertical: 'center',
            includeFontPadding: false,
          }}
          onPress={() => selectGender('none')}
        />
      </Menu>
      <ScreenReaderHidden
        webStyle={{ marginTop: 8 }}
        nativeStyle={{ marginTop: 8 }}
      >
        <HelperText
          type="info"
          nativeID="gender-description"
          style={{ paddingHorizontal: 0 }}
        >
          {i18n.translate('app.fields.gender.helper_text')}
        </HelperText>
      </ScreenReaderHidden>
    </View>
  );
}

function CountryInput({
  value,
  onChangeValue,
}: {
  value: string | undefined;
  onChangeValue(next: string | null): void;
}) {
  const { show, showing, dismiss, finish } = useModalState(onChangeValue);
  const { data: countries, isLoading } = usePublicCountries();

  const { label, icon } = useMemo(() => {
    const country = (countries?._embedded || []).find(
      ({ country }) => value === country.code2
    );
    if (!country) {
      return {
        label: i18n.translate('app.fields.country.options.null'),
        icon: undefined,
      };
    }

    const {
      country: {
        name,
        _links: {
          flag: { href },
        },
      },
    } = country;

    return {
      label: name,
      icon: { uri: href, width: 16, height: 16 },
    };
  }, [value, countries]);

  return (
    <View
      style={{
        position: 'relative',
        width: '100%',
        marginTop: 24,
        paddingVertical: 8,
      }}
    >
      <ScreenReaderHidden>
        <Text variant="labelSmall" style={{ display: 'flex' }}>
          {i18n.translate('app.fields.country.label')}
        </Text>
      </ScreenReaderHidden>
      <Button
        onPress={show}
        mode="contained-tonal"
        uppercase={false}
        accessibilityLabel={i18n.translate('app.fields.country.label')}
        accessibilityHint={i18n.translate('app.fields.country.helper_text')}
        accessibilityLanguage={i18n.locale}
        labelStyle={{
          textAlign: 'left',
          marginLeft: 24,
          marginRight: 'auto',
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
        style={{ borderRadius: 8, marginTop: 4 }}
        icon={(props) =>
          icon ? (
            Platform.OS === 'web' ? (
              <img
                key={icon.uri}
                src={icon.uri}
                style={{ width: 16, height: 16, minWidth: 16, minHeight: 16 }}
              />
            ) : (
              <SwallowBrokenSvg>
                <SvgUri
                  key={icon.uri}
                  {...icon}
                  {...props}
                  style={{ width: 16, height: 16, minWidth: 16, minHeight: 16 }}
                />
              </SwallowBrokenSvg>
            )
          ) : (
            <View
              style={{
                width: 16,
                height: 16,
                backgroundColor: 'rgba(0, 0, 0, .32)',
              }}
            />
          )
        }
      >
        {label}
      </Button>

      <ScreenReaderHidden
        webStyle={{ marginTop: 8 }}
        nativeStyle={{ marginTop: 8 }}
      >
        <HelperText
          type="info"
          nativeID="country-description"
          style={{ paddingHorizontal: 0 }}
        >
          {i18n.translate('app.fields.country.helper_text')}
        </HelperText>
      </ScreenReaderHidden>

      <Portal>
        <CountriesDialog
          options={countries}
          visible={showing}
          onDismiss={dismiss}
          onSelect={finish}
        />
      </Portal>
    </View>
  );
}

function CountriesDialog_({
  options,
  visible,
  onDismiss,
  onSelect,
}: {
  options: SoundersCountriesCollection | undefined;
  visible: boolean;
  onDismiss(): void;
  onSelect(next: string | null): void;
}) {
  const renderItem = ({
    item: {
      country: {
        code2,
        name,
        _links: {
          flag: { href },
        },
      },
    },
  }: ListRenderItemInfo<{
    country: SoundersCountry;
  }>) => {
    return (
      <List.Item
        key={code2}
        title={name}
        onPress={() => onSelect(code2)}
        titleStyle={{
          marginLeft: 10,
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
      />
    );
  };

  const optionNL = useMemo(
    () => options?._embedded?.find(({ country: { code2 } }) => code2 === 'NL'),
    [options]
  )?.country;

  const isSmall = useMatchMedia('(max-width: 440px)');

  return (
    <Dialog
      visible={visible}
      onDismiss={onDismiss}
      style={{
        width: '100%',
        maxWidth: 560,
        alignSelf: 'center',
        borderRadius: isSmall ? 0 : 10,
        maxHeight: 560,
        height: '100%',
      }}
    >
      <Dialog.Title>{i18n.translate('app.fields.country.title')}</Dialog.Title>
      <Dialog.ScrollArea
        style={{
          paddingHorizontal: 0,
          maxHeight: isSmall ? undefined : 400,
          flex: 1,
        }}
      >
        <FlatList
          ListHeaderComponent={() =>
            optionNL ? (
              <View>
                <List.Item
                  key="NL"
                  title={optionNL.name}
                  onPress={() => onSelect('NL')}
                  titleStyle={{
                    marginLeft: 4,
                    includeFontPadding: false,
                    textAlignVertical: 'center',
                  }}
                  left={() =>
                    Platform.OS === 'web' ? (
                      <img
                        src={optionNL._links.flag.href}
                        width={16}
                        height={16}
                        style={{
                          marginTop: 16,
                          marginBottom: 16,
                          marginLeft: 16,
                        }}
                      />
                    ) : (
                      <SwallowBrokenSvg>
                        <SvgUri
                          uri={optionNL._links.flag.href}
                          width={16}
                          height={16}
                          style={{
                            marginVertical: 10,
                            marginLeft: 16,
                          }}
                        />
                      </SwallowBrokenSvg>
                    )
                  }
                />
                <Divider style={{ height: StyleSheet.hairlineWidth }} />
              </View>
            ) : null
          }
          contentContainerStyle={{ paddingHorizontal: 0 }}
          data={options?._embedded || []}
          keyExtractor={(item) => item.country._links.self.href}
          renderItem={renderItem}
        />
      </Dialog.ScrollArea>
    </Dialog>
  );
}

const CountriesDialog = React.memo(CountriesDialog_);

function ProfileTagsInput({
  selected,
  onSelect,
  options,
  loading,
}: {
  selected: string[];
  options: SoundersProfileTagsCollection | undefined;
  onSelect(next: string[]): void;
  loading: boolean;
}) {
  const label = useMemo(() => {
    if (selected.length === 0 || !options) {
      return i18n.translate('app.fields.tags.options.null');
    }

    const first = options._embedded.find(
      (option) => option.tag._links.self.slug === selected[0]
    )?.tag.name;

    if (!first) {
      return i18n.translate('app.fields.tags.options.count', {
        length: selected.length,
      });
    }

    if (selected.length === 1) {
      return first;
    }

    return i18n.translate('app.fields.tags.options.value', {
      length: selected.length - 1,
      tag: first,
    });
  }, [selected, options]);

  const [nextSelected, setNextSelected] = useState(selected);
  const { show, dismiss, showing, finish } = useModalState(onSelect);

  // Update selected when source changes
  useEffect(() => {
    setNextSelected(selected);
  }, [selected]);
  const { top, bottom } = useSafeAreaInsets();

  return (
    <View
      style={{
        position: 'relative',
        width: '100%',
        marginTop: 24,
        paddingVertical: 8,
      }}
    >
      <ScreenReaderHidden>
        <Text variant="labelSmall" style={{ display: 'flex' }}>
          {i18n.translate('app.fields.tags.label')}
        </Text>
      </ScreenReaderHidden>

      <Menu
        style={{
          marginTop: top,
          marginBottom: bottom,
          maxWidth: Platform.select({ web: '80vw', default: '80%' }),
        }}
        anchor={
          <Button
            onPress={show}
            mode="contained-tonal"
            uppercase={false}
            accessibilityLabel={i18n.translate('app.fields.tags.label')}
            accessibilityHint={i18n.translate('app.fields.tags.helper_text')}
            accessibilityLanguage={i18n.locale}
            labelStyle={{
              textAlign: 'left',
              marginLeft: 12,
              marginRight: 'auto',
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            style={{ borderRadius: 8, marginTop: 4 }}
          >
            {label}
          </Button>
        }
        visible={showing}
        onDismiss={() => {
          dismiss();
          finish(nextSelected);
        }}
      >
        {(options?._embedded || []).map(
          ({
            tag: {
              name,
              _links: {
                self: { slug },
              },
            },
          }) => (
            <Checkbox.Item
              key={slug}
              label={name}
              color={PRIMARY_LIGHT}
              status={nextSelected.includes(slug) ? 'checked' : 'unchecked'}
              onPress={() => {
                setNextSelected((prev) => {
                  const deleteIndex = prev.indexOf(slug);
                  if (deleteIndex === -1) {
                    return [...prev, slug];
                  } else {
                    const next = prev.slice();
                    next.splice(deleteIndex, 1);
                    return next;
                  }
                });
              }}
            />
          )
        )}
      </Menu>

      <ScreenReaderHidden
        webStyle={{ marginTop: 8 }}
        nativeStyle={{ marginTop: 8 }}
      >
        <HelperText
          type="info"
          nativeID="tags-description"
          style={{ paddingHorizontal: 0 }}
        >
          {i18n.translate('app.fields.tags.helper_text')}
        </HelperText>
      </ScreenReaderHidden>
    </View>
  );
}

export function PreferencesScreen(): JSX.Element {
  const { section } = useRoute<RouteProp<RootStackParamList, 'Preferences'>>()
    .params || { section: 'preferences' };

  if (section === 'how-it-works') {
    return <TutorialScreen />;
  }

  if (section === 'soundality') {
    return <ProfilerScreen />;
  }

  return <PreferencesInputSection />;
}

function PreferencesInputSection() {
  const { section } = useRoute<RouteProp<RootStackParamList, 'Preferences'>>()
    .params || { section: 'preferences' };
  const isMounted = useIsMounted();
  const forceUpdate = useForceUpdate();
  const gotoUrl = useLinkTo();

  const {
    version,
    profileRef,
    prepare,
    mutation: { mutateAsync: updateProfile, isLoading: isUpdating },
  } = useUpdateProfile();

  const { data: selected, isLoading: isLoadingTags } = useUserProfileTags({
    enabled: section === 'preferences',
  });
  const { data: options, isLoading: isLoadingOptions } = usePublicProfileTags({
    enabled: section === 'preferences',
  });

  const selectedRef = useRef([] as string[]);

  useEffect(() => {
    if (!selected || !options) {
      return;
    }

    selectedRef.current = selected._embedded.map((s) => s.tag._links.self.slug);
    forceUpdate();
  }, [selected]);

  const nextCountryCodeRef = useRef<string | undefined>(undefined);

  const submit = useCallback(() => {
    const { current: nextProfile } = profileRef;

    if (!nextProfile) {
      console.warn('nextProfile is empty', profileRef);
      return;
    }

    const onboardedFeatures = nextProfile.onboarded_features
      .concat(['preferences.2020-07-20'])
      .filter((feature, index, self) => self.indexOf(feature) === index);

    nextProfile.onboarded_features = onboardedFeatures;

    const patch = prepare({
      next: nextProfile,
      nextCountryCode: nextCountryCodeRef.current,
      nextProfileTags: selectedRef.current,
    });

    if (!patch) {
      console.warn('patch is empty', patch);
      return;
    }

    return updateProfile({ patch })
      .then(() => isMounted.current && resetToPath('/'))
      .catch((error: unknown) => {
        console.error(error);
      });
  }, [
    prepare,
    updateProfile,
    gotoUrl,
    isMounted,
    selectedRef,
    nextCountryCodeRef,
    profileRef,
  ]);

  const onSelectGender = useCallback((next: string | null) => {
    profileRef.current!.gender = next;
    forceUpdate();
  }, []);

  const onSelectCountry = useCallback((next: string | null) => {
    nextCountryCodeRef.current = next || undefined;

    forceUpdate();
  }, []);

  const onSelectProfileTags = useCallback(
    (next: string[]) => {
      selectedRef.current = next;
      forceUpdate();
    },
    [selectedRef]
  );

  // Set the initial data
  useEffect(() => {
    nextCountryCodeRef.current = profileRef.current?._links.country?.code2;
    forceUpdate();
  }, [profileRef.current?._links.self.href]);

  const isReady = profileRef.current?.name !== undefined || version > 0;
  const { top, bottom } = useSafeAreaInsets();

  return (
    <Portal.Host>
      <KeyboardAvoidingView
        style={{ height: Platform.select({ web: '100%', default: undefined }) }}
        behavior={Platform.select({
          ios: 'padding',
          android: 'height',
          default: undefined,
        })}
      >
        <ScrollView
          style={{
            width: '100%',
            height: '100%',
            maxHeight: Platform.select({ web: '100vh', default: '100%' }),
          }}
          contentContainerStyle={{
            width: '100%',
            maxWidth: 600,
            minHeight: '100%',
            alignSelf: 'center',
            overflow: 'hidden',
            paddingTop: top,
            paddingBottom: bottom,
          }}
        >
          <View
            style={{
              minHeight: 400,
              maxHeight: 3000,
              width: '100%',
              maxWidth: '100%',
              justifyContent: 'space-evenly',
              flex: 1,
              paddingVertical: 32,
            }}
          >
            {isReady ? (
              <Fragment>
                <View style={{ paddingTop: 0 }}>
                  <AnimatePresence exitBeforeEnter>
                    {section === 'preferences' ? (
                      <Presence key="title">
                        <PreferencesTitle />
                      </Presence>
                    ) : (
                      <Presence key="title">
                        <PreferencesDoneTitle />
                      </Presence>
                    )}
                  </AnimatePresence>
                  <AnimatePresence exitBeforeEnter>
                    {section === 'preferences' ? (
                      <Presence key="description" delay={40}>
                        <PreferencesDescription />
                      </Presence>
                    ) : (
                      <Presence key="description" delay={40}>
                        <PreferencesDoneDescription />
                      </Presence>
                    )}
                  </AnimatePresence>
                </View>
                <AnimatePresence>
                  {section === 'preferences' ? (
                    <Presence key="form" delay={80}>
                      <FormWrapper>
                        <NameInput
                          key={profileRef.current ? 'done' : 'current'}
                          disabled={!!isUpdating}
                          defaultValue={profileRef.current?.name || ''}
                          onBlur={forceUpdate}
                          onChangeText={(text) => {
                            if (profileRef.current) {
                              profileRef.current.name = text;
                            }
                          }}
                        />

                        <GenderInput
                          displayValue={genderValueToDisplay(
                            profileRef.current?.gender || null
                          )}
                          onChangeValue={onSelectGender}
                        />

                        <BornAtField
                          value={profileRef.current?.born_at || null}
                          onChange={(next: string) => {
                            if (profileRef.current) {
                              profileRef.current.born_at = next;
                            }

                            forceUpdate();
                          }}
                        />

                        <CountryInput
                          value={nextCountryCodeRef.current}
                          onChangeValue={onSelectCountry}
                        />

                        <ProfileTagsInput
                          selected={selectedRef.current}
                          onSelect={onSelectProfileTags}
                          options={options}
                          loading={isLoadingTags || isLoadingOptions}
                        />
                      </FormWrapper>
                    </Presence>
                  ) : (
                    <Presence key="form" delay={80}>
                      <PreferencesDoneWhatsNext />
                    </Presence>
                  )}

                  <Presence key="submit" delay={120}>
                    <View style={{ alignItems: 'center', marginVertical: 20 }}>
                      {section === 'preferences' ? (
                        <PrimaryButton
                          label={i18n.translate(
                            'app.preferences.actions.next.label'
                          )}
                          onPress={submit}
                          loading={isUpdating}
                          disabled={!isReady || isUpdating}
                        />
                      ) : (
                        <PrimaryButton
                          label="Let's go"
                          onPress={() =>
                            resetToPath('/getting-started/how-it-works')
                          }
                        />
                      )}
                    </View>
                  </Presence>
                </AnimatePresence>
              </Fragment>
            ) : null}
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </Portal.Host>
  );
}

function genderValueToDisplay(gender: string | null): string {
  if (gender === null) {
    return i18n.translate('app.fields.gender.options.null');
  }

  if (
    gender === 'male' ||
    gender === 'female' ||
    gender === 'other' ||
    gender === 'none'
  ) {
    return i18n.translate(`app.fields.gender.options.${gender}`);
  }

  return genderValueToDisplay(null);
}

class SwallowBrokenSvg extends Component<
  { children: React.ReactNode },
  { error?: Error }
> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    if (this.state?.error) {
      return null;
    }

    return this.props.children;
  }
}
