import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { Platform, StatusBar } from 'react-native';
import { Appbar } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { goToPath, resetToPath } from '../navigation/utils';
import { PRIMARY_DARK } from '../theming';

export function BackHeader() {
  const onGoBack = useGoBack();
  const { top } = useSafeAreaInsets();

  return (
    <Appbar.Header
      style={{
        zIndex: 1,
        maxWidth: 800,
        margin: 'auto',
        width: '100%',

        backgroundColor: 'transparent',
        elevation: 0,
        paddingHorizontal: 36,
        paddingLeft: 32,
        height: 52 + 36,
      }}
      statusBarHeight={Platform.select({
        ios: StatusBar.currentHeight || top || 20,
        default: undefined,
      })}
    >
      <Appbar.BackAction
        size={24}
        color="#fff"
        onPress={onGoBack}
        style={{
          borderRadius: 54 / 2,
          backgroundColor: `${PRIMARY_DARK}40`,
          width: 42,
          height: 42,
          marginLeft: -2,
        }}
      />
    </Appbar.Header>
  );
}

export function useGoBack(fallback: string = '/discover') {
  const { canGoBack, goBack } = useNavigation();
  return useCallback(() => {
    if (canGoBack()) {
      // dispatch(StackActions.popToTop() as any);
      // resetToPath('/discover');
      // goToPath(fallback);
      goBack();
      // resetToPath(fallback);
    } else {
      resetToPath(fallback);
    }
  }, [fallback]);
}
