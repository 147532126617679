import { useIsFocused } from '@react-navigation/native';
import { fetchMediaWrapped } from 'fetch-media';
import { RefObject, useCallback, useEffect, useReducer, useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ReadonlyDeep } from 'type-fest';
import { runOnLogout, useToken } from '../hooks/useToken';
import { i18n } from '../locale';
import { MediaPlayer } from '../player/MediaPlayer';
import { authorization } from '../utils/authorization';
import {
  ApiEventTrack,
  ApiEventTracks,
  ApiTrackStreamVariants,
  ApiTrackWaveVariants,
  useEventTracks,
} from './useEventTracks';

export type VoteMetadata = {
  seconds_to_reaction: number;
  seconds_to_vote: number;
  seconds_listened: number;
  seconds_listened_unique: number;
  listen_timestamps: [number, number][];
};

type VoteExperienceState = ReadonlyDeep<{
  track: ApiEventTrack | null | undefined;
  tracksUrl: string | null;
  nextTracks: ApiEventTrack[];
  nextTracksUrl: string | null | undefined;

  completed: number | undefined;
  total: number | undefined;

  loading: boolean;
  paginating: 'requested' | boolean;
  voting: boolean;
  profiling: 'requested' | undefined | boolean;
}>;

type VoteExperienceAction =
  | MarkAsVotingAction
  | GotoNextTrackAction
  | LoadingCompletedAction
  | PaginateAction
  | RequestProfiler;

type MarkAsVotingAction = {
  type: 'voting';
};

type GotoNextTrackAction = {
  type: 'next';
};

type PaginateAction = {
  type: 'paginate';
};

type LoadingCompletedAction = {
  type: 'loaded';
  tracks: ApiEventTracks;
  nextTracksUrl: string | null;
};

type RequestProfiler = {
  type: 'profiling';
  nextTracksUrl: string;
};

export const VOTED: Record<string, boolean> = {};
runOnLogout(() => {
  Object.keys(VOTED).forEach((key) => delete VOTED[key]);
});

function reducer(
  state: VoteExperienceState,
  action: VoteExperienceAction
): VoteExperienceState {
  switch (action.type) {
    case 'loaded': {
      const { tracks } = action.tracks;
      const [track, ...nextTracks] = tracks._embedded;

      return {
        ...state,

        track: track ? track : undefined,
        nextTracks,
        nextTracksUrl: action.nextTracksUrl,

        completed: 'progress' in tracks ? tracks.progress : undefined,
        total: 'total_count' in tracks ? tracks.total_count : undefined,

        loading: false,
        paginating: false,
        profiling: track === undefined ? 'requested' : false,
      };
    }

    case 'paginate': {
      return {
        ...state,
        tracksUrl: state.nextTracksUrl || null,
        nextTracksUrl: null,
        paginating: true,
        profiling: !state.nextTracksUrl ? 'requested' : false,
      };
    }

    case 'voting': {
      return {
        ...state,
        voting: true,
      };
    }

    case 'next': {
      const [track, ...nextTracks] = state.nextTracks;

      return {
        ...state,
        voting: false,
        track: track ? track : undefined,
        nextTracks,
        completed:
          state.completed === undefined ? state.completed : state.completed + 1,
        paginating: track === undefined ? 'requested' : false,
      };
    }

    case 'profiling': {
      return {
        ...state,
        tracksUrl: action.nextTracksUrl,
      };
    }
  }

  return state;
}

function makeEmptyState(initialTracksUrl: string): VoteExperienceState {
  return {
    track: undefined,
    nextTracks: [],
    tracksUrl: null,
    nextTracksUrl: initialTracksUrl,
    completed: 0,
    total: 0,

    paginating: 'requested',
    voting: false,
    profiling: undefined,
    loading: true,
  };
}

export interface VoteExperience {
  track: ApiEventTrack | null | undefined;

  loading: boolean;
  error: Error | null | undefined;
  done: boolean;

  completed: number | undefined;
  total: number | undefined;

  hrefs: {
    wave: string | undefined;
    stream: string | undefined;
    cover: string | undefined;
  };

  react(how: 'tap' | 'drag'): void;
  vote(rating: number): Promise<unknown>;
}

export function useVoteExperience(
  initialTracksUrl: string,
  playerRef: RefObject<MediaPlayer | null>,
  profiler: boolean,
  onVerificationRequired: () => void,
  onExplanationRequired: () => void
): VoteExperience {
  const voteHrefRef = useRef<null | string>(null);
  const isFocused = useIsFocused();

  const [state, dispatch] = useReducer(
    reducer,
    initialTracksUrl,
    makeEmptyState
  );

  const { track, loading, total, completed, profiling, paginating } = state;
  const {
    track: {
      _links: {
        self: { href: selfHref },
        vote: { href: voteHref },
        wave,
        stream,
        cover_image,
      },
      name,
    },
  } = track || {
    track: {
      _links: {
        self: { href: null },
        vote: { href: null },
      },

      name: '',
    },
  };

  // When track changes, update metaHref
  const metaRef = useRef<{
    lastTimestamp: number;
    currentTimestamps: ([number, number] | [number])[];
    playingAt: number | null;
    reactionAt: number | null;
  }>({
    lastTimestamp: 0,
    currentTimestamps: [[0]],
    playingAt: null,
    reactionAt: null,
  });

  useEffect(() => {
    console.log('[player] reset player');

    metaRef.current = {
      lastTimestamp: 0,
      currentTimestamps: [[0]],
      playingAt: null,
      reactionAt: null,
    };

    voteHrefRef.current = voteHref;
  }, [selfHref]);

  const onUpdate = useCallback(
    (
      position: {
        progress: number | null;
        current: number | null;
        total: number | null;
      },
      status: {
        isPlaying: boolean;
      }
    ) => {
      // Mark playing
      if (metaRef.current.playingAt === null && status.isPlaying) {
        metaRef.current.playingAt = new Date().getTime();
      }

      const lastTimestamp = position.current ?? metaRef.current.lastTimestamp;
      metaRef.current.lastTimestamp = lastTimestamp;

      if (lastTimestamp === 0) {
        console.log('[player] position at start');
      }
    },
    [metaRef]
  );

  const onSeek = useCallback(
    (from: number, to: number) => {
      // Handle a seek
      const { currentTimestamps, lastTimestamp } = metaRef.current;

      // Finish the previous timestamp array
      const last = currentTimestamps.pop()!;
      if (last.length === 1) {
        last.push(lastTimestamp);
      }

      currentTimestamps.push(last);

      // The new seeked position is the new start
      currentTimestamps.push([to]);

      metaRef.current = {
        ...metaRef.current,
        currentTimestamps,
        lastTimestamp: to,
      };

      console.log(`[player] seek (${from} -> ${to})`);
    },
    [metaRef]
  );

  const onFinish = useCallback(() => {
    const { currentTimestamps, lastTimestamp } = metaRef.current;

    // Finish the previous timestamp array
    const last = currentTimestamps.pop()!;
    last.push(lastTimestamp);
    currentTimestamps.push(last);

    // It will start again at the beginning
    currentTimestamps.push([0]);

    metaRef.current = {
      ...metaRef.current,
      currentTimestamps,
      lastTimestamp: 0,
    };

    console.log('[player] finished playing');
  }, []);

  const stop = useCallback(() => {
    playerRef.current?.stop();
  }, [playerRef]);

  useEffect(() => {
    const removeStatusListener = playerRef.current?.addStatusListener(
      (status) => {
        const progress = playerRef.current?.progress ?? null;

        if (status.didJustFinish) {
          onFinish();
        }

        onUpdate(
          {
            progress,
            current: status.positionMillis,
            total: status.durationMillis ?? null,
          },
          {
            isPlaying: status.isPlaying,
          }
        );
      }
    );

    const removeSeekListener = playerRef.current?.addSeekListener(
      (from, to) => {
        onSeek(from, to);
      }
    );

    return () => {
      removeStatusListener && removeStatusListener();
      removeSeekListener && removeSeekListener();
    };
  }, [playerRef, onUpdate, onFinish, onSeek]);

  useEffect(() => {
    if (!isFocused) {
      stop();
    }
  }, [isFocused]);

  const react = useCallback(
    (how: 'tap' | 'drag') => {
      console.log('[player] you are reacting!', how);

      // Mark reaction
      if (!metaRef.current.reactionAt) {
        metaRef.current.reactionAt = new Date().getTime();
      }
    },
    [metaRef]
  );

  const { ref } = useToken();
  const vote = useMutation(
    ['vote'],
    async (rating: number) => {
      if (!ref.current?.token || !voteHrefRef.current) {
        throw new Error('Not ready');
      }

      const nowTimestamp = new Date().getTime();

      const { currentTimestamps, lastTimestamp, playingAt, reactionAt } =
        metaRef.current;

      const last = currentTimestamps.pop();
      if (last?.length === 1) {
        // If last timestamp is after previous one
        if (lastTimestamp > last[0]) {
          last.push(lastTimestamp);

          // If seek or end of track happened before vote
        } else if (lastTimestamp === 0) {
          const length = playerRef.current?.state.playbackInstanceDuration;
          if (length) {
            last.push(length);

            // Use reaction time if we don't know the length of the track
          } else if (reactionAt) {
            last.push(reactionAt);
          }
        }
      }

      if (last?.length === 2) {
        currentTimestamps.push(last);
      }

      const listen_timestamps = (currentTimestamps as [number, number][])
        .filter(([from, to]) => from < to)
        .map(([from, to]) => [
          Math.round(from / 10) / 100,
          Math.round(to / 10) / 100,
        ]);

      const meta: VoteMetadata = listen_timestamps.reduce(
        (result, [from, to]) => {
          return {
            ...result,
            seconds_listened: result.seconds_listened + (to! - from!),
          };
        },
        {
          seconds_to_reaction:
            reactionAt === null || playingAt === null
              ? -1
              : Math.round((reactionAt - playingAt) / 10) / 100,
          seconds_to_vote:
            playingAt === null
              ? -1
              : Math.round((nowTimestamp - playingAt) / 10) / 100,

          // map timestamps to seconds
          listen_timestamps,
          seconds_listened: 0,
          seconds_listened_unique: -1,
        } as VoteMetadata
      );

      const listened = Number(meta.seconds_listened.toFixed(2));
      const unique = Math.min(
        listened,
        Number(uniqueSeconds(meta.listen_timestamps).toFixed(2))
      );

      const body = {
        vote: {
          rating,
          ...meta,
          seconds_listened: listened,
          seconds_listened_unique: unique,
        },
      };

      VOTED[voteHrefRef.current] = true;

      console.log('[player] voted: thank you!');

      return fetchMediaWrapped(voteHrefRef.current, {
        headers: {
          accept: '*/*',
          authorization: authorization(ref.current)!,
          contentType: 'application/vnd.sounders.vote.v1+json',
        },
        method: 'POST',
        body,
        debug: __DEV__,
      });
    },
    {
      onMutate: () => {
        dispatch({ type: 'voting' });
      },
      onSettled: () => {
        stop();
        dispatch({ type: 'next' });
      },
    }
  );

  const { error, data, isLoading, isLoadingError, isError } = useEventTracks(
    state.tracksUrl,
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const client = useQueryClient();

  useEffect(() => {
    if (!data) {
      return;
    }

    // Not a tracks list
    if ('profiler' in data) {
      const previousUrl = state.tracksUrl;
      client.invalidateQueries([i18n.locale, previousUrl, 'tracks']);

      if (profiler) {
        dispatch({
          type: 'profiling',
          nextTracksUrl: data.profiler.href,
        });
      } else {
        onExplanationRequired();
      }
      return;
    }

    if ('verified' in data) {
      if (data.verified === false) {
        client.invalidateQueries([i18n.locale, state.tracksUrl, 'tracks']);
        onVerificationRequired();
      }
      return;
    }

    dispatch({
      type: 'loaded',
      tracks: data,
      nextTracksUrl: data.tracks._links.next?.href || null,
    });
  }, [data, profiler, onVerificationRequired, onExplanationRequired]);

  // Skip track if already voted
  useEffect(() => {
    if (voteHref && VOTED[voteHref]) {
      stop();
      dispatch({ type: 'next' });
    }
  }, [voteHref]);

  const play = useCallback(
    (uri: string, name: string, type: 'audio' | 'video') => {
      playerRef.current?.play(uri, name, type);
    },
    [playerRef]
  );

  // Play the current track when it's first loaded
  //
  useEffect(() => {
    if (!wave?.href && !stream?.href) {
      return;
    }

    // Don't auto play if already voted
    if (voteHref && VOTED[voteHref]) {
      console.debug(
        '[experience] already voted on this track; not auto-playing'
      );
      return;
    }

    if (!isFocused) {
      return;
    }

    // TODO use bandwidth settings
    if (stream?.href) {
      const uri = stream.href.replace(
        /(%7[Bb]|{)variant(%7[Dd]|})/,
        '160b-1080:1920' satisfies ApiTrackStreamVariants
      );
      play(uri, name, 'video');
    } else if (wave?.href) {
      const uri = wave.href.replace(
        /(%7[Bb]|{)variant(%7[Dd]|})/,
        '160b' satisfies ApiTrackWaveVariants
      );
      play(uri, name, 'audio');
    }
  }, [wave?.href, play, isFocused]);

  // Paginate
  //
  useEffect(() => {
    if (paginating === 'requested') {
      dispatch({ type: 'paginate' });
    }
  }, [paginating]);

  return {
    track,
    vote: vote.mutateAsync,
    react,
    hrefs: {
      wave: wave?.href ?? undefined,
      stream: stream?.href ?? undefined,
      cover: cover_image?.href ?? undefined,
    },
    loading,
    error,
    total,
    completed,
    done: profiling === 'requested',
  };
}

function uniqueSeconds(timestamps: [number, number][], precision = 0.1) {
  if (!timestamps || timestamps.length === 0) {
    return 0;
  }

  precision = timestamps.length > 25 ? 0.5 : precision;
  precision = timestamps.length > 100 ? 1 : precision;

  let min = Number.MAX_SAFE_INTEGER;
  let max = -1;

  timestamps.forEach(([from, to]) => {
    min = Math.min(min, from);
    max = Math.max(max, to);
  });

  let count = 0;
  for (let i = min; i < max; i += precision) {
    if (timestamps.some(([from, to]) => from <= i && to > i)) {
      count += precision;
    }
  }

  return count;
}
