import { MaterialBottomTabNavigationProp } from '@react-navigation/material-bottom-tabs';
import {
  RouteProp,
  useNavigation,
  useRoute,
  useTheme,
} from '@react-navigation/native';
import { AnimatePresence } from 'moti';
import React, { useCallback, useRef, useState } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  TextInput as NativeTextInput,
  View,
} from 'react-native';
import {
  Appbar,
  Button,
  Dialog,
  HelperText,
  Portal,
  Text,
  TextInput,
} from 'react-native-paper';
import { PrimaryButton } from '../components/Button';
import { Presence } from '../components/Presence';
import { ScreenScrollView } from '../components/ScreenScrollView';
import { useSnackbar } from '../components/SnackbarProvider';
import { useLocale } from '../hooks/useLocale';
import { useMatchMedia } from '../hooks/useMatchMedia';
import { defineTranslations, i18n } from '../locale';
import { resetToPath } from '../navigation/utils';
import {
  DARK_PURPLE,
  PASTEL_PINK,
  PASTEL_PURPLE,
  PRIMARY_DARK,
} from '../theming';
import { BaseParamList } from '../types';
import { useThemedColor } from '../utils/useThemedColor';
import { FormWrapper } from './FormWrapper';
import { SocialLogin } from './SocialLogin';
import {
  ConflictError,
  PasswordError,
  useAuthenticate,
  useForgotPassword,
  useRegister,
  useResetPassword,
  UsernameError,
} from './useAuthentication';

defineTranslations({
  en: {
    app: {
      auth: {
        alternative_sign_in_header: 'Or sign in using',
        forgot_password_body: 'Don’t worry. This happens to the best of us.',
        toast_recovery_sent:
          'If we find an account with that e-mail, you will receive a recovery code within minutes.',

        actions: {
          goto_signup: 'Create account',
          goto_signin: 'Sign in',
          goto_forgot_password: 'Forgot your password?',
          remember_password: 'Reset password',
          forgot_password: 'Email me recovery instructions',
          forgot_password_entry: 'Enter recovery code',
          forgot_password_cancel: 'Cancel',

          signup: 'Register',
          signin: 'Sign in',
        },

        titles: {
          authenticating: 'Logging you in...',
          registering: 'Registering...',
          signin: 'Welcome back!',
          signup: 'Hello there!',
          forgot_password: 'Forgot password?',
        },

        fields: {
          email: {
            label: 'E-mail address',
          },
          password: {
            label: 'Password',
          },
          password_confirmation: {
            label: 'Password confirmation',
          },
          recovery_code: {
            label: 'Recovery code',
          },
        },
      },
    },
  },
  nl: {
    app: {
      auth: {
        alternative_sign_in_header: 'Of maak gebruik van',
        forgot_password_body: 'Geen zorgen. Dat overkomt de beste.',
        toast_recovery_sent:
          'Als je e-mail bij ons bekend is ontvang je binnen enkele minuten een herstelcode.',

        actions: {
          goto_signup: 'Registreren',
          goto_signin: 'Inloggen',
          goto_forgot_password: 'Wachtwoord vergeten?',
          remember_password: 'Wachtwoord herstellen',
          forgot_password: 'Mail mij herstel instructies',
          forgot_password_entry: 'Herstelcode invoeren',
          forgot_password_cancel: 'Annuleer',

          signup: 'Registreren',
          signin: 'Inloggen',
        },

        titles: {
          authenticating: 'Aan het inloggen...',
          registering: 'Aan het registeren...',
          signin: 'Welkom terug!',
          signup: 'Hallo daar!',
          forgot_password: 'Wachtwoord vergeten?',
        },

        fields: {
          email: {
            label: 'E-mail adres',
          },
          password: {
            label: 'Wachtwoord',
          },
          password_confirmation: {
            label: 'Wachtwoord bevestiging',
          },
          recovery_code: {
            label: 'Herstelcode',
          },
        },
      },
    },
  },
});

export function LoginScreen() {
  const { section } = useRoute<RouteProp<BaseParamList, 'Landing'>>()
    .params || { section: 'login' };

  const [isRecovering, setIsRecovering] = useState(false);

  const { authenticating = false } =
    (useRoute().params as {
      authenticating: boolean;
    }) || {};

  const {
    mutateAsync: authenticate,
    isLoading: isAuthenticating,
    error: authenticationError,
  } = useAuthenticate();

  const {
    mutateAsync: register,
    isLoading: isRegistering,
    error: registrationError,
  } = useRegister({
    onError: (error) => {
      if (error instanceof ConflictError) {
        resetToPath('/landing/login');
        show(error.message);
      }
    },
  });

  const {
    mutateAsync: forgot,
    isLoading: isRequesting,
    error: forgotError,
  } = useForgotPassword();

  const {
    mutateAsync: reset,
    isLoading: isResetting,
    error: resetError,
  } = useResetPassword();

  const { show } = useSnackbar();
  const isDark = useTheme().dark;

  const usernameInputRef = useRef<NativeTextInput | null>(null);
  const passwordInputRef = useRef<NativeTextInput | null>(null);
  const passwordConfirmationInputRef = useRef<NativeTextInput | null>(null);
  const recoveryCodeInputRef = useRef<NativeTextInput | null>(null);

  const usernameRef = useRef('');
  const passwordRef = useRef('');
  const passwordConfirmationRef = useRef('');
  const recoveryCodeRef = useRef('');

  const error =
    section === 'login'
      ? authenticationError
      : section === 'register'
      ? registrationError
      : isRecovering
      ? resetError
      : forgotError;

  const usernameError = error && error instanceof UsernameError ? error : null;
  const passwordError = error && error instanceof PasswordError ? error : null;
  const conflictError = error && error instanceof ConflictError ? error : null;

  const genericError =
    error && !usernameError && !passwordError && !conflictError ? error : null;

  const isLoading = isAuthenticating || isRegistering;

  const { dark } = useTheme();
  const isSmall = useMatchMedia('(max-width: 560px)');

  const { jumpTo } =
    useNavigation<MaterialBottomTabNavigationProp<BaseParamList, 'Landing'>>();

  const submit = useCallback(() => {
    switch (section) {
      case 'login': {
        return authenticate({
          username: usernameRef.current,
          password: passwordRef.current,
        }).catch(() => {});
      }
      case 'register': {
        return register({
          username: usernameRef.current,
          password: passwordRef.current,
          passwordConfirmation: passwordConfirmationRef.current,
        }).catch(() => {});
      }
      case 'forgot-password': {
        return isRecovering
          ? reset({
              email: usernameRef.current,
              password: passwordRef.current,
              passwordConfirmation: passwordConfirmationRef.current,
              resetToken: recoveryCodeRef.current,
            })
              .then(() => {
                setIsRecovering(false);

                return authenticate({
                  username: usernameRef.current,
                  password: passwordRef.current,
                });
              })
              .catch(() => {})
          : forgot({ email: usernameRef.current })
              .then(() => setIsRecovering(true))
              .then(() => show(i18n.translate('app.auth.toast_recovery_sent')))
              .catch(() => {});
      }
      default: {
        console.error(section);
      }
    }
  }, [
    section,
    authenticate,
    register,
    usernameRef,
    passwordRef,
    passwordConfirmationRef,
    isRecovering,
  ]);

  return (
    <KeyboardAvoidingView
      style={{ height: Platform.select({ web: '100%', default: undefined }) }}
      behavior={Platform.select({
        ios: 'padding',
        android: 'height',
        default: undefined,
      })}
    >
      <ScreenScrollView
        style={{
          width: '100%',
          height: '100%',
          maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        }}
        contentContainerStyle={{
          width: '100%',
          maxWidth: 600,
          minHeight: '100%',
          alignSelf: 'center',
        }}
        useWindowScrolling={false}
      >
        <Header />
        <View
          style={{
            minHeight: 400,
            maxHeight: 1200,
            width: '100%',
            maxWidth: '100%',
            justifyContent: 'space-evenly',
            flex: 1,
          }}
        >
          <AnimatePresence>
            <Presence>
              <Text
                selectionColor={PASTEL_PINK}
                style={{
                  color: dark ? 'white' : PRIMARY_DARK,
                  maxWidth: 300,
                  marginHorizontal: 38,
                  fontWeight: '700',
                  display: 'flex',
                }}
                variant="displayMedium"
              >
                {section !== 'register'
                  ? isAuthenticating
                    ? i18n.translate('app.auth.titles.authenticating')
                    : i18n.translate('app.auth.titles.signin')
                  : isRegistering
                  ? i18n.translate('app.auth.titles.registering')
                  : i18n.translate('app.auth.titles.signup')}
              </Text>
            </Presence>
          </AnimatePresence>
          <AnimatePresence>
            <Presence key="form" delay={40}>
              <FormWrapper authenticating={authenticating}>
                <View>
                  <TextInput
                    ref={usernameInputRef}
                    mode="flat"
                    style={{
                      backgroundColor: 'transparent',
                      paddingHorizontal: 0,
                      textAlignVertical: 'center',
                      includeFontPadding: false,
                    }}
                    label={i18n.translate('app.auth.fields.email.label')}
                    numberOfLines={1}
                    keyboardType="email-address"
                    returnKeyType="next"
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoComplete="email"
                    error={!!usernameError}
                    onChangeText={(next) => {
                      usernameRef.current = next;
                    }}
                    theme={{
                      colors: { primary: isDark ? PASTEL_PINK : PASTEL_PURPLE },
                    }}
                    onSubmitEditing={() => passwordInputRef.current?.focus()}
                    {...Platform.select({
                      android: { autoCompleteType: 'email' },
                      ios: { textContentType: 'emailAddress' },
                      default: {},
                    })}
                  />
                  {usernameError ? (
                    <HelperText type="error" style={{ paddingLeft: 0 }}>
                      {usernameError.message}
                    </HelperText>
                  ) : null}
                </View>
                <View>
                  <TextInput
                    ref={passwordInputRef}
                    mode="flat"
                    style={{
                      backgroundColor: 'transparent',
                      paddingHorizontal: 0,
                      marginTop: 8,
                    }}
                    label={i18n.translate('app.auth.fields.password.label')}
                    numberOfLines={1}
                    returnKeyType="next"
                    secureTextEntry
                    autoCapitalize="none"
                    autoCorrect={false}
                    error={!!passwordError}
                    onChangeText={(next) => {
                      passwordRef.current = next;
                    }}
                    theme={{
                      colors: { primary: isDark ? PASTEL_PINK : PASTEL_PURPLE },
                    }}
                    onSubmitEditing={
                      section === 'login'
                        ? submit
                        : () => passwordConfirmationInputRef.current?.focus()
                    }
                    {...Platform.select({
                      android: {
                        autoCompleteType: 'password',
                        autoComplete:
                          section === 'login' ? 'password' : 'password-new',
                      },
                      ios: {
                        textContentType:
                          section === 'login' ? 'password' : 'newPassword',
                        autoComplete:
                          section === 'login' ? 'password' : 'password-new',
                      },
                      web: {
                        autoComplete:
                          'new-password' as string as 'password-new',
                      },
                      default: {},
                    })}
                  />
                  {passwordError ? (
                    <HelperText type="error" style={{ paddingLeft: 0 }}>
                      {passwordError.message}
                    </HelperText>
                  ) : null}
                </View>
                <AnimatePresence>
                  {section === 'register' ? (
                    <Presence>
                      <View>
                        <TextInput
                          ref={passwordConfirmationInputRef}
                          mode="flat"
                          style={{
                            backgroundColor: 'transparent',
                            paddingHorizontal: 0,
                            marginTop: 8,
                          }}
                          label={i18n.translate(
                            'app.auth.fields.password_confirmation.label'
                          )}
                          numberOfLines={1}
                          returnKeyType="next"
                          secureTextEntry
                          autoCapitalize="none"
                          autoCorrect={false}
                          onChangeText={(next) => {
                            passwordConfirmationRef.current = next;
                          }}
                          theme={{
                            colors: {
                              primary: isDark ? PASTEL_PINK : PASTEL_PURPLE,
                            },
                          }}
                          onSubmitEditing={submit}
                          {...Platform.select({
                            android: {
                              autoCompleteType: 'newPassword',
                              autoComplete: 'password-new',
                            },
                            ios: {
                              textContentType: 'password',
                              autoComplete: 'password-new',
                            },
                            web: {
                              autoComplete:
                                'new-password' as string as 'password-new',
                            },
                            default: {},
                          })}
                        />
                      </View>
                    </Presence>
                  ) : null}
                </AnimatePresence>
                <AnimatePresence>
                  {section !== 'register' ? (
                    <Presence>
                      <View style={{ height: 64, marginTop: 8 }}>
                        <Button
                          disabled={isLoading}
                          mode="text"
                          textColor={dark ? '#FFFFFFAA' : PRIMARY_DARK}
                          style={{
                            marginLeft: 'auto',
                            alignItems: 'flex-end',
                            borderRadius: 10,
                          }}
                          labelStyle={{
                            textAlign: 'right',
                            fontSize: 12,
                            lineHeight: 16,
                            textAlignVertical: 'center',
                            includeFontPadding: false,
                          }}
                          uppercase={false}
                          onPress={() =>
                            jumpTo('Landing', { section: 'forgot-password' })
                          }
                        >
                          {i18n.translate(
                            'app.auth.actions.goto_forgot_password'
                          )}
                        </Button>
                      </View>
                    </Presence>
                  ) : null}
                </AnimatePresence>
              </FormWrapper>
            </Presence>
          </AnimatePresence>

          <View style={{ alignItems: 'center', marginTop: 20 }}>
            <AnimatePresence>
              <Presence delay={80} key="primary">
                <PrimaryButton
                  disabled={isLoading || authenticating}
                  loading={isLoading}
                  label={
                    section === 'register'
                      ? i18n.translate('app.auth.actions.signup')
                      : i18n.translate('app.auth.actions.signin')
                  }
                  onPress={submit}
                />
              </Presence>
            </AnimatePresence>
            <AnimatePresence exitBeforeEnter>
              {genericError ? (
                <Presence key="error">
                  <HelperText type="error">
                    {(genericError as Error).message}
                  </HelperText>
                </Presence>
              ) : (
                <HelperText type="error">&nbsp;</HelperText>
              )}
            </AnimatePresence>
          </View>
          <AnimatePresence>
            <Presence>
              <View style={{ alignItems: 'center', marginTop: 20 }}>
                <Text
                  variant="labelMedium"
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                  }}
                >
                  {i18n.translate('app.auth.alternative_sign_in_header')}
                </Text>
                <SocialLogin />
              </View>
            </Presence>
          </AnimatePresence>
        </View>
      </ScreenScrollView>

      <Portal>
        <Dialog
          visible={section === 'forgot-password'}
          onDismiss={() => jumpTo('Landing', { section: 'login' })}
          style={{
            width: '100%',
            maxWidth: 560,
            alignSelf: 'center',
            borderRadius: isSmall ? 0 : 13,
          }}
        >
          <Dialog.Title
            style={{
              color: dark ? 'white' : DARK_PURPLE,
              marginHorizontal: isSmall ? 24 : 38,
              paddingTop: 12,
            }}
          >
            {i18n.translate('app.auth.titles.forgot_password')}
          </Dialog.Title>
          <Dialog.Content style={{ paddingHorizontal: 0 }}>
            <Text
              style={{
                marginHorizontal: isSmall ? 24 : 38,
                color: dark ? 'white' : DARK_PURPLE,
                display: 'flex',
              }}
              variant="bodyLarge"
            >
              {i18n.translate('app.auth.forgot_password_body')}
            </Text>

            <FormWrapper marginHorizontal={isSmall ? 24 : 38}>
              <TextInput
                mode="flat"
                style={{
                  backgroundColor: 'transparent',
                  paddingHorizontal: 0,
                }}
                label={i18n.translate('app.auth.fields.email.label')}
                numberOfLines={1}
                keyboardType="email-address"
                returnKeyType={isRecovering ? 'next' : 'send'}
                autoCapitalize="none"
                autoCorrect={false}
                defaultValue={usernameRef.current}
                onChangeText={(text) => {
                  usernameRef.current = text;
                }}
                {...Platform.select({
                  android: { autoCompleteType: 'email' },
                  ios: { textContentType: 'emailAddress' },
                  default: {},
                })}
                theme={{
                  colors: { primary: isDark ? PASTEL_PINK : PASTEL_PURPLE },
                }}
                onSubmitEditing={
                  isRecovering
                    ? () => {
                        passwordInputRef.current?.focus();
                      }
                    : submit
                }
              />

              {isRecovering ? (
                <TextInput
                  ref={passwordInputRef}
                  mode="flat"
                  style={{
                    backgroundColor: 'transparent',
                    paddingHorizontal: 0,
                    marginTop: 8,
                  }}
                  label={i18n.translate('app.auth.fields.password.label')}
                  numberOfLines={1}
                  returnKeyType="next"
                  secureTextEntry
                  autoCapitalize="none"
                  autoCorrect={false}
                  onChangeText={(next) => {
                    passwordRef.current = next;
                  }}
                  {...Platform.select({
                    android: {
                      autoCompleteType: 'newPassword',
                      autoComplete: 'password-new',
                    },
                    ios: {
                      textContentType: 'password',
                      autoComplete: 'password-new',
                    },
                    web: {
                      autoComplete: 'new-password' as string as 'password-new',
                    },
                    default: {},
                  })}
                  theme={{
                    colors: { primary: isDark ? PASTEL_PINK : PASTEL_PURPLE },
                  }}
                  onSubmitEditing={() => {
                    passwordConfirmationInputRef.current?.focus();
                  }}
                />
              ) : null}

              {isRecovering ? (
                <TextInput
                  ref={passwordConfirmationInputRef}
                  mode="flat"
                  style={{
                    backgroundColor: 'transparent',
                    paddingHorizontal: 0,
                    marginTop: 8,
                  }}
                  label={i18n.translate(
                    'app.auth.fields.password_confirmation.label'
                  )}
                  numberOfLines={1}
                  returnKeyType="next"
                  secureTextEntry
                  autoCapitalize="none"
                  autoCorrect={false}
                  onChangeText={(next) => {
                    passwordConfirmationRef.current = next;
                  }}
                  onSubmitEditing={() => {
                    recoveryCodeInputRef.current?.focus();
                  }}
                  autoComplete="password-new"
                  theme={{
                    colors: { primary: isDark ? PASTEL_PINK : PASTEL_PURPLE },
                  }}
                  {...Platform.select({
                    android: {
                      autoCompleteType: 'newPassword',
                      autoComplete: 'password-new',
                    },
                    ios: {
                      textContentType: 'password',
                      autoComplete: 'password-new',
                    },
                    web: {
                      autoComplete: 'new-password' as string as 'password-new',
                    },
                    default: {},
                  })}
                />
              ) : null}

              {isRecovering ? (
                <TextInput
                  ref={recoveryCodeInputRef}
                  mode="flat"
                  style={{
                    backgroundColor: 'transparent',
                    paddingHorizontal: 0,
                    marginTop: 8,
                  }}
                  label={i18n.translate('app.auth.fields.recovery_code.label')}
                  numberOfLines={1}
                  returnKeyType="send"
                  autoCapitalize="none"
                  autoCorrect={false}
                  onChangeText={(next) => {
                    recoveryCodeRef.current = next;
                  }}
                  theme={{
                    colors: { primary: isDark ? PASTEL_PINK : PASTEL_PURPLE },
                  }}
                  onSubmitEditing={submit}
                  autoComplete="off"
                  textContentType="none"
                />
              ) : null}
            </FormWrapper>

            <View style={{ alignItems: 'center', marginTop: 20 }}>
              <PrimaryButton
                label={
                  isRecovering
                    ? i18n.translate('app.auth.actions.remember_password')
                    : i18n.translate('app.auth.actions.forgot_password')
                }
                onPress={submit}
                loading={isRequesting}
                disabled={isRequesting}
              />
              <Button
                style={{ marginTop: 12 }}
                mode="text"
                textColor={useTheme().dark ? PASTEL_PINK : undefined}
                onPress={() => {
                  setIsRecovering((prev) => !prev);
                }}
              >
                {isRecovering
                  ? i18n.translate('app.auth.actions.forgot_password_cancel')
                  : i18n.translate('app.auth.actions.forgot_password_entry')}
              </Button>
            </View>
          </Dialog.Content>
        </Dialog>
      </Portal>
    </KeyboardAvoidingView>
  );
}

function Header() {
  const locale = useLocale();
  const color = useThemedColor(DARK_PURPLE, PASTEL_PINK);

  const { section } = useRoute<RouteProp<BaseParamList, 'Landing'>>()
    .params || { section: 'login' };

  return (
    <Appbar.Header style={{ backgroundColor: 'transparent', elevation: 0 }}>
      <Button
        mode="text"
        style={{
          marginLeft: 20,
          borderRadius: 10,
        }}
        labelStyle={{
          color,
          fontSize: 16,
          textAlign: 'left',
          textAlignVertical: 'center',
          includeFontPadding: false,
        }}
        onPress={
          section !== 'login'
            ? () => resetToPath('/landing/login')
            : () => resetToPath('/landing/register')
        }
        uppercase={false}
        accessibilityLanguage={locale}
      >
        {section !== 'register'
          ? i18n.translate('app.auth.actions.goto_signup')
          : i18n.translate('app.auth.actions.goto_signin')}
      </Button>
    </Appbar.Header>
  );
}
