import { useIsFocused, useRoute } from '@react-navigation/native';
import { fetchMedia, FetchMediaError } from 'fetch-media';
import { AnimatePresence } from 'moti';
import { Image, Platform, View } from 'react-native';
import { Button, Surface, Text } from 'react-native-paper';
import { useQuery } from 'react-query';
import { BackHeader } from '../components/BackHeader';
import { ErrorScreen } from '../components/ErrorScreen';
import { Presence } from '../components/Presence';
import { ScreenScrollView } from '../components/ScreenScrollView';
import { EXPO_IDENTIFIER } from '../config';
import { EventCard } from '../discover/DiscoverEventCard';
import { GrotePrijsVanNederlandCard } from '../discover/GrotePrijsVanNederlandCard';
import { isGrotePrijsEvent } from '../events/isSpecialEvent';
import { ApiEvent } from '../events/useEvent';
import { useConfiguration } from '../hooks/useConfiguration';
import { SoundersToken, useToken } from '../hooks/useToken';
import { resetToPath } from '../navigation/utils';
import { DARK_PURPLE, PASTEL_PINK, PASTEL_PURPLE } from '../theming';
import { authorization } from '../utils/authorization';
import { useThemedColor } from '../utils/useThemedColor';
import { variantImageUrl } from '../utils/variants';

export function SharedTrackScreen() {
  const { token } = useToken();

  if (token) {
    return <Authenticated token={token} />;
  }

  if (Platform.OS === 'web') {
    return <CallToAction />;
  }

  return <UnAuthenticated />;
}

function CallToAction() {
  const { id, id2, id3 } = (useRoute().params || {}) as {
    id: string;
    id2?: string;
    id3?: string;
  };

  const path = ['share', id, id2, id3].filter(Boolean).join('/');
  const color = useThemedColor(PASTEL_PURPLE, PASTEL_PINK);

  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 8,
      }}
    >
      <Surface
        style={{
          padding: 24,
          borderRadius: 12,
          maxWidth: 500,
          marginHorizontal: 'auto',
        }}
      >
        <Text variant="headlineLarge">Stemmen?</Text>
        <Text variant="bodyMedium" style={{ marginTop: 12, maxWidth: 480 }}>
          Ga naar{' '}
          <a href="https://www.groteprijsvan.nl/soundersapp/" style={{ color }}>
            https://www.groteprijsvan.nl/soundersapp/
          </a>{' '}
          en klik op de Android of iOS store knop. Installeer de app, login in,
          keer daarna terug naar deze pagina en gebruik de link hieronder.
        </Text>

        <Text variant="bodyMedium" style={{ marginTop: 12 }}>
          Zit je op een iPhone of iPad? Kwam je hier via een Instagram,
          Facebook, of Whatsapp link? Dan werkt onderstaande link vaak niet
          direct. Je moet in het browser menu eerst kiezen voor Systeembrowser.
        </Text>

        <Text variant="bodyMedium" style={{ marginTop: 16 }}>
          <a
            id="app-link"
            href={`${EXPO_IDENTIFIER}:///${path}`}
            style={{ color }}
            target="_blank"
          >
            Ik heb de app geïnstalleerd.
          </a>
        </Text>

        <script>{`
          if ('userAgent' in navigator && navigator.userAgent.includes("Instagram")) {
            document.getElementByID('app-link').textContext = "Je zit in de Instagram browser, dus deze linkt werkt misschien niet. Open de link in een normale browser."
          }
        `}</script>
      </Surface>
    </View>
  );
}

function UnAuthenticated() {
  const { id, id2, id3 } = (useRoute().params || {}) as {
    id: string;
    id2?: string;
    id3?: string;
  };

  const path = ['share', id, id2, id3].filter(Boolean).join('/');

  const focused = useIsFocused();
  const { data } = useConfiguration({ enabled: focused });

  const url =
    data && 'invitation' in data._links
      ? data._links.invitation.href.replace(
          /{invite}|%7Binvite%7D/,
          encodeURIComponent(path)
        )
      : null;

  const { data: shareData, error: shareError } = useQuery({
    queryKey: [url],
    queryFn: () => {
      return fetchMedia(url!, {
        headers: {
          accept: 'application/vnd.soundersmusic.track.share.v1+json',
        },
      }) as Promise<ApiTrackShare>;
    },
    enabled: Boolean(focused && url),
  });

  if (!shareData && shareError) {
    return <ErrorScreen error={shareError as FetchMediaError} />;
  }

  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: 16,
      }}
    >
      <Surface style={{ padding: 24, borderRadius: 12 }}>
        <Text variant="headlineLarge">Stemmen?</Text>
        <AnimatePresence>
          <Presence>
            {shareData?.share ? (
              <View
                style={{
                  maxWidth: '100%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    maxWidth: 375,
                    marginTop: 12,
                    marginBottom: 12,
                  }}
                >
                  <Image
                    source={{
                      uri: variantImageUrl(
                        shareData.share._links.inviter.href,
                        'thumbnail'
                      ),
                    }}
                    style={{
                      width: 64,
                      height: 64,
                      borderRadius: 8,
                      backgroundColor: DARK_PURPLE,
                      marginRight: 16,
                      flexShrink: 0,
                    }}
                  />

                  <Text
                    variant="titleMedium"
                    style={{ paddingTop: 8, flex: 1 }}
                  >
                    {shareData.share.message.replace(
                      '{{name}}',
                      shareData.share.name
                    )}
                  </Text>
                </View>
              </View>
            ) : null}
          </Presence>
        </AnimatePresence>
        <Text variant="bodyMedium" style={{ marginTop: 8, maxWidth: 480 }}>
          Je bent op dit moment nog niet ingelogd. Keer terug, log in/of
          registreer. Daarna kan je de deellink nog een keer proberen door de
          app helemaal af te sluiten en opnieuw op de link te klikken.
        </Text>
      </Surface>

      <Button
        onPress={() => resetToPath('/')}
        mode="contained-tonal"
        style={{ marginTop: 24 }}
      >
        Terug naar het beginscherm
      </Button>
    </View>
  );
}

function Authenticated({ token }: { token: SoundersToken }) {
  const { id, id2, id3 } = (useRoute().params || {}) as {
    id: string;
    id2?: string;
    id3?: string;
  };

  const path = ['share', id, id2, id3].filter(Boolean).join('/');

  const focused = useIsFocused();
  const { data } = useConfiguration({ enabled: focused });

  const url =
    data && 'invitation' in data._links
      ? data._links.invitation.href.replace(
          /{invite}|%7Binvite%7D/,
          encodeURIComponent(path)
        )
      : null;

  const { data: shareData, error: shareError } = useQuery({
    queryKey: [url],
    queryFn: () => {
      return fetchMedia(url!, {
        headers: {
          accept: 'application/vnd.soundersmusic.track.share.v1+json',
          authorization: authorization(token),
        },
      }) as Promise<ApiTrackShare>;
    },
    enabled: Boolean(focused && url),
  });

  if (!shareData && shareError) {
    return <ErrorScreen error={shareError as FetchMediaError} />;
  }

  return (
    <ScreenScrollView
      style={{
        width: '100%',
        height: '100%',
        maxHeight: Platform.select({ web: '100vh', default: '100%' }),
      }}
      contentContainerStyle={{
        width: '100%',
        minHeight: '100%',
        alignSelf: 'center',
        paddingHorizontal: 16,
      }}
      useWindowScrolling={false}
    >
      <View
        style={{
          minHeight: 400,
          maxHeight: 1200,
          width: '100%',
          maxWidth: '100%',
          justifyContent: 'space-evenly',
          flex: 1,
        }}
      >
        <View
          style={{ maxWidth: 375, marginHorizontal: 'auto', width: '100%' }}
        >
          <AnimatePresence>
            <Presence>
              {shareData?.share ? (
                <View
                  style={{
                    maxWidth: '100%',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      maxWidth: 375,
                      width: '100%',
                    }}
                  >
                    <Image
                      source={{
                        uri: variantImageUrl(
                          shareData.share._links.inviter.href,
                          'thumbnail'
                        ),
                      }}
                      style={{
                        width: 64,
                        height: 64,
                        borderRadius: 8,
                        backgroundColor: DARK_PURPLE,
                        marginRight: 16,
                        flexShrink: 0,
                      }}
                    />

                    <Text
                      variant="titleMedium"
                      style={{ paddingTop: 8, flex: 1 }}
                    >
                      {shareData.share.message.replace(
                        '{{name}}',
                        shareData.share.name
                      )}
                    </Text>
                  </View>
                </View>
              ) : null}
            </Presence>
          </AnimatePresence>

          <View style={{ height: 48 }} />

          <AnimatePresence>
            <Presence>
              <SharedEventCard
                event={shareData?.share._embedded?.event}
                cursor={shareData?.share._embedded?.cursor}
              />
            </Presence>
          </AnimatePresence>
        </View>
      </View>

      <View
        style={{
          position: 'absolute',
          maxWidth: 600,
          alignSelf: 'center',
          width: '100%',
        }}
      >
        <BackHeader />
      </View>
    </ScreenScrollView>
  );
}

function SharedEventCard({
  event,
  cursor,
}: {
  event: NonNullable<ApiTrackShare['share']['_embedded']>['event'] | undefined;
  cursor: string | undefined;
}) {
  if (!event) {
    return null;
  }

  if (isGrotePrijsEvent({ event })) {
    return (
      <GrotePrijsVanNederlandCard
        event={event as ApiEvent['event']}
        progress={undefined}
        cursor={cursor}
      />
    );
  }

  return (
    <EventCard
      event={event as ApiEvent['event']}
      progress={undefined}
      cursor={cursor}
    />
  );
}

type ApiTrackShare = {
  share: {
    _links: {
      self: {
        href: string;
      };
      share: {
        href: string;
      };
      inviter: {
        href: string | null;
        name: string;
        primary: null | string;
      };
    };
    name: string;
    message: string;
    track?: {
      _links: {
        cover?: {
          href: string | null;
        };
      };
      artist: string;
      name: string;
    };

    _embedded?: {
      cursor: string;
      event: {
        _links: {
          self: {
            href: string;
            title: string;
          };
          background_image?: {
            href: string;
            templated: true;
          };
          foreground_image?: {
            href: string;
            templated: true;
          };
        };
        start_at: string;
        end_at: string | null;
        background_color: string | null;
        primary: string | null;
        description: string;
        subtitle: string | null;
      };
    };
  };
};
