import { useLinkProps } from '@react-navigation/native';
import { Platform, StyleSheet, View } from 'react-native';
import { Button, Surface, Text, useTheme } from 'react-native-paper';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { AnimatedImage } from '../components/AnimatedImage';
import { AspectRatio } from '../components/AspectRatio';
import { PressableSurface } from '../components/PressableSurface';
import { ApiEvent } from '../events/useEvent';
import { ApiEventProgress } from '../events/useEventProgress';
import { i18n } from '../locale';
import { prepareUrl } from '../navigation/LinkingConfiguration';
import { goToPath, resetToPath } from '../navigation/utils';
import { DARK_PURPLE, PRIMARY_LIGHT } from '../theming';
import { sanitizeColor } from '../utils/sanitzeColor';
import { useLerpWidth } from '../utils/useLerpWidth';
import { IMAGE_PIXEL_RATIO, variantImageUrl } from '../utils/variants';

const GPVNL_DISABLED = false;
const GPVNL_RANKING_DISABLED = false;

export function GrotePrijsVanNederlandCard({
  event,
  progress,
  cursor,
}: {
  progress: ApiEventProgress | undefined;
  skeleton?: string;
  cursor?: string;
} & ApiEvent) {
  const { dark } = useTheme();

  const backgroundColor = sanitizeColor(
    event.background_color,
    dark ? DARK_PURPLE : '#FFF'
  );
  const primaryColor = sanitizeColor(event.primary, PRIMARY_LIGHT);
  const textColor = '#FFF';

  const ratio = 346 / 280;
  const linkProps = useLinkProps({
    to: [
      `/events/${prepareUrl(event._links.self.href)}`,
      cursor ? `cursor=${encodeURIComponent(cursor)}` : undefined,
    ]
      .filter(Boolean)
      .join('?'),
  });

  const rankingLinkProps = useLinkProps({
    to: [`/events/${prepareUrl(event._links.self.href)}/ranking`]
      .filter(Boolean)
      .join('?'),
  });

  const timestamp = useInaccurateTimestamp({ every: 30 * 1000 });
  const started = Boolean(
    event.start_at &&
      new Date(timestamp).getTime() > new Date(event.start_at).getTime()
  );
  const finished = Boolean(
    event.end_at &&
      new Date(timestamp).getTime() > new Date(event.end_at).getTime()
  );

  const open = started && !finished;

  // 375 designed width
  //
  // title:
  // - font size 30
  // - line height 34
  // - margin bottom 32
  //
  // description:
  // - font size 16
  // - line height 22

  const lerpWidth = useLerpWidth([315, 0], [375, 1]);
  const imageHeight = useLerpWidth([315, 40], [375, 100]);
  const marginBottom = useLerpWidth([386, 0], [436, 12]);

  return (
    <AspectRatio
      style={[
        {
          width: '100%',
          overflow: 'visible',
          marginBottom: 16,
        },
        { aspectRatio: ratio },
      ]}
    >
      <Surface
        theme={{ roundness: 26 / 4 }}
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          backgroundColor: dark ? DARK_PURPLE : '#666',
          borderRadius: 10,
          opacity: 1,
        }}
        elevation={1}
      >
        <BackgroundImage
          src={event?._links?.background_image?.href}
          color={backgroundColor}
          cover
        />

        <View
          style={{
            padding: 20,
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {event?._links?.foreground_image?.href ? (
            <View
              style={{
                maxWidth: '100%',
                position: 'relative',
                height: imageHeight,
                maxHeight: 100,
                marginBottom,
              }}
            >
              <ForegroundImage
                src={event?._links?.foreground_image?.href}
                alt={[event._links.self.title, event.subtitle]
                  .filter(Boolean)
                  .join(' - ')}
              />
            </View>
          ) : (
            <Text
              variant="titleMedium"
              numberOfLines={2}
              style={{
                letterSpacing: 0.2,
                maxWidth: 400,
                marginRight: 72,
                minHeight: 40,
                includeFontPadding: false,
                color: textColor,
                display: 'flex',

                height: imageHeight,

                fontSize: 24 + lerpWidth * 6, // target: 30
                lineHeight: 30 + lerpWidth * 4, // target: 34
                marginBottom: 12 + 0.2 * 20, // target: 32

                textShadowColor: '#0000004A',
                textShadowOffset: { width: 1, height: 1 },
                textShadowRadius: 0,
              }}
            >
              {event._links.self.title || ' '}
              {'\n'}
            </Text>
          )}
          <Text
            variant="bodyMedium"
            numberOfLines={4}
            style={{
              color: '#FFF',
              includeFontPadding: false,
              display: 'flex',

              fontSize: 12 + lerpWidth * 2, // target: 16
              lineHeight: 20 + lerpWidth * 2, // target: 22

              textShadowColor: '#0000004A',
              textShadowOffset: { width: 1, height: 1 },
              textShadowRadius: 1,
            }}
          >
            {event.description}{' '}
          </Text>
        </View>
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            flexDirection: 'row',
            padding: 20,
          }}
        >
          <Button
            mode="contained"
            buttonColor={
              event.subtitle?.toLocaleLowerCase() === 'instroom'
                ? '#2C2D82'
                : '#000'
            }
            textColor="#FFF"
            style={{ marginRight: 12 }}
            labelStyle={{ fontWeight: '500' }}
            contentStyle={{ height: 44 }}
            disabled={GPVNL_DISABLED || GPVNL_RANKING_DISABLED}
            {...(GPVNL_DISABLED || GPVNL_RANKING_DISABLED
              ? undefined
              : rankingLinkProps)}
          >
            {i18n.translate('app.discovery.actions.goto_ranking')}
          </Button>
          <Button
            mode="contained"
            buttonColor={
              event.subtitle?.toLocaleLowerCase() === 'instroom'
                ? '#FEFD7E'
                : '#F5C100'
            }
            textColor="#000"
            disabled={!open || GPVNL_DISABLED}
            labelStyle={{ fontWeight: '500' }}
            contentStyle={{ flexDirection: 'row-reverse', height: 44 }}
            icon="arrow-right"
            {...(open && !GPVNL_DISABLED ? linkProps : undefined)}
          >
            {i18n.translate('app.discovery.actions.goto_vote')}
          </Button>
        </View>
      </Surface>
    </AspectRatio>
  );
}

function BackgroundImage({
  src,
  color,
  cover = true,
}: {
  src?: string;
  color: string;
  cover?: boolean;
}) {
  const { roundness } = useTheme();

  if (!src) {
    const backgroundColor = sanitizeColor(color, '#FFFFFFFF');

    return (
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            borderRadius: roundness,
            backgroundColor,
          },
        ]}
      />
    );
  }

  const size = IMAGE_PIXEL_RATIO * 346;
  const uri = variantImageUrl(src, 'size');

  return (
    <AnimatedImage
      fadeDuration={0}
      animateOpacity
      transition={{ overshootClamping: true }}
      resizeMode={cover ? 'cover' : 'contain'}
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: roundness,
      }}
      source={{ uri, width: size, height: size }}
    />
  );
}

function ForegroundImage({
  src,
  alt,
  animate,
}: {
  src?: string;
  alt: string;
  animate?: boolean;
}) {
  if (!src) {
    return null;
  }

  const size = IMAGE_PIXEL_RATIO * 346;
  const uri = variantImageUrl(src, 'size');

  return (
    <AnimatedImage
      accessibilityLabel={alt}
      fadeDuration={0}
      animateY={animate ? -30 : undefined}
      animateOpacity
      resizeMode="contain"
      style={{
        width: Platform.select({
          web: undefined,
          android: '100%',
          ios: '100%',
        }),
        maxHeight: '100%',
        height: Platform.select({
          web: undefined,
          android: '100%',
          ios: '100%',
        }),
        maxWidth: Platform.select({
          web: '100%',
          android: undefined,
          ios: undefined,
        }),
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      source={{ uri, width: size, height: Math.round((size / 346) * 280) }}
    />
  );
}
