import {
  Audio,
  AVPlaybackStatus,
  AVPlaybackStatusError,
  AVPlaybackStatusSuccess,
  InterruptionModeAndroid,
  InterruptionModeIOS,
  Video,
  VideoFullscreenUpdateEvent,
  VideoReadyForDisplayEvent,
} from 'expo-av';
import { Component } from 'react';
import { Dimensions } from 'react-native';

const LOADING_STRING = '... loading ...';
const BUFFERING_STRING = '...buffering...';
const LOOPING_TYPE_ALL = 0;
const LOOPING_TYPE_ONE = 1;
const RATE_SCALE = 3.0;

type MediaPlayerState = {
  showVideo: boolean;
  shouldPlay: boolean;
  shouldCorrectPitch: boolean;
  isPlaying: boolean;
  isBuffering: boolean;
  isLoading: boolean;
  rate: number;
  volume: number;
  muted: boolean;
  loopingType: typeof LOOPING_TYPE_ALL | typeof LOOPING_TYPE_ONE;

  playbackInstanceName: string;
  playbackInstanceDuration: number | null;
  playbackInstancePosition: number | null;

  poster: boolean;
  useNativeControls: boolean;
  fullscreen: boolean;
  throughEarpiece: boolean;

  videoWidth: number | undefined;
  videoHeight: number | undefined;
};

export class MediaPlayer extends Component<
  { children: React.ReactNode },
  MediaPlayerState
> {
  private playbackInstance: undefined | Video | Audio.Sound;

  public isSeeking: boolean;

  private video: undefined | Video;
  private shouldPlayAtEndOfSeek: boolean;
  private statusListeners: ((next: AVPlaybackStatusSuccess) => void)[];
  private seekListeners: ((from: number, to: number) => void)[];

  constructor(props: { children: React.ReactNode }) {
    super(props);

    this.isSeeking = false;
    this.shouldPlayAtEndOfSeek = false;
    this.playbackInstance = undefined;
    this.statusListeners = [];
    this.seekListeners = [];

    this.state = {
      showVideo: false,
      playbackInstanceName: LOADING_STRING,
      loopingType: LOOPING_TYPE_ALL,
      muted: false,
      playbackInstancePosition: null,
      playbackInstanceDuration: null,
      shouldPlay: false,
      isPlaying: false,
      isBuffering: false,
      isLoading: true,
      shouldCorrectPitch: true,
      volume: 1.0,
      rate: 1.0,
      poster: false,
      useNativeControls: false,
      fullscreen: false,
      throughEarpiece: false,
      videoHeight: undefined,
      videoWidth: undefined,
    };
  }

  public get progress(): number {
    if (
      this.state.playbackInstancePosition === null ||
      this.state.playbackInstanceDuration === null
    ) {
      return 0;
    }

    if (this.state.playbackInstanceDuration === 0) {
      return 0;
    }

    return (
      this.state.playbackInstancePosition / this.state.playbackInstanceDuration
    );
  }

  componentWillUnmount() {
    this.stop();
  }

  addStatusListener(listener: (next: AVPlaybackStatusSuccess) => void) {
    this.statusListeners.push(listener);

    return () => {
      const index = this.statusListeners.indexOf(listener);
      if (index !== -1) {
        this.statusListeners.splice(index, 1);
      }
    };
  }

  addSeekListener(listener: (from: number, to: number) => void) {
    this.seekListeners.push(listener);

    return () => {
      const index = this.seekListeners.indexOf(listener);
      if (index !== -1) {
        this.seekListeners.splice(index, 1);
      }
    };
  }

  async _loadNewPlaybackInstance(
    playing: boolean,
    options: {
      name: string | null;
      uri: string | null;
      type: 'video' | 'audio';
    } | null
  ) {
    if (this.playbackInstance) {
      // This will also happen when unmounting the video element
      await this.playbackInstance.unloadAsync().catch(() => {});
      this.playbackInstance = undefined;
    }

    const source = { uri: options?.uri as string };

    const initialStatus = {
      shouldPlay: playing,
      rate: this.state.rate,
      shouldCorrectPitch: this.state.shouldCorrectPitch,
      volume: this.state.volume,
      isMuted: this.state.muted,
      isLooping: this.state.loopingType === LOOPING_TYPE_ONE,
    };

    switch (options?.type) {
      case 'video': {
        this.playbackInstance = this.video;
        console.log('[player] setting video playback instance');

        if (source.uri) {
          await this.video?.loadAsync(source, initialStatus);
        }

        // this.video.onPlaybackStatusUpdate(this._onPlaybackStatusUpdate);

        const status = await this.video?.getStatusAsync();
        break;
      }

      case 'audio': {
        const { sound, status } = await Audio.Sound.createAsync(
          source,
          initialStatus,
          this._onPlaybackStatusUpdate
        );
        this.playbackInstance = sound;
        break;
      }

      default: {
        console.log('[player] Did not get a type to load');
      }
    }

    this._updateScreenForLoading(
      false,
      options ? options?.name : this.state.playbackInstanceName,
      options ? options.type === 'video' : this.state.showVideo
    );
  }

  _mountVideo = (component: Video) => {
    this.video = component;

    Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
      staysActiveInBackground: true,
    }).catch(() => {});

    this._loadNewPlaybackInstance(true, {
      name: null,
      uri: null,
      type: 'video',
    });
  };

  _updateScreenForLoading(
    isLoading: boolean,
    name?: string | null,
    video?: boolean
  ) {
    if (isLoading) {
      this.setState({
        showVideo: false,
        isPlaying: false,
        playbackInstanceName: LOADING_STRING,
        playbackInstanceDuration: null,
        playbackInstancePosition: null,
        isLoading: true,
      });
    } else {
      this.setState({
        playbackInstanceName: name ?? '-',
        showVideo: video ?? false,
        isLoading: false,
      });
    }
  }

  _onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      this.setState({
        playbackInstancePosition: status.positionMillis,
        playbackInstanceDuration: status.durationMillis ?? null,
        shouldPlay: status.shouldPlay,
        isPlaying: status.isPlaying,
        isBuffering: status.isBuffering,
        rate: status.rate,
        muted: status.isMuted,
        volume: status.volume,
        loopingType: status.isLooping ? LOOPING_TYPE_ONE : LOOPING_TYPE_ALL,
        shouldCorrectPitch: status.shouldCorrectPitch,
      });

      this.statusListeners.forEach((listener) => listener(status));
    } else {
      if (status.error) {
        console.log(`[player] FATAL PLAYER ERROR: ${status.error}`);
      }
    }
  };

  _onLoadStart = () => {
    console.log(`[player] ON LOAD START`);
  };

  _onLoad = (status: AVPlaybackStatus) => {
    console.log(`[player] ON LOAD : ${JSON.stringify(status)}`);
  };

  _onError = (error: string) => {
    console.log(`[player] ON ERROR : ${error}`);
  };

  _onReadyForDisplay = (event: VideoReadyForDisplayEvent) => {
    const { width: DEVICE_WIDTH, height: DEVICE_HEIGHT } =
      Dimensions.get('window');

    const VIDEO_CONTAINER_HEIGHT = DEVICE_HEIGHT;
    const videoHeight = event.naturalSize?.height || 1920;
    const videoWidth = event.naturalSize?.width || 1080;

    const widestHeight = (DEVICE_WIDTH * videoHeight) / videoWidth;
    if (widestHeight > VIDEO_CONTAINER_HEIGHT) {
      this.setState({
        videoWidth: (VIDEO_CONTAINER_HEIGHT * videoWidth) / videoHeight,
        videoHeight: VIDEO_CONTAINER_HEIGHT,
      });
    } else {
      this.setState({
        videoWidth: DEVICE_WIDTH,
        videoHeight: (DEVICE_WIDTH * videoHeight) / videoWidth,
      });
    }
  };

  _onFullscreenUpdate = (event: VideoFullscreenUpdateEvent) => {
    console.log(
      `[player] FULLSCREEN UPDATE : ${JSON.stringify(event.fullscreenUpdate)}`
    );
  };

  togglePlay = () => {
    if (this.playbackInstance) {
      if (this.state.isPlaying) {
        this.playbackInstance.pauseAsync();
      } else {
        if (this.progress >= 0.9999) {
          this.seek(0);
        }

        this.playbackInstance.playAsync();
      }
    } else {
      console.warn('Cannot play because there is no playbackInstance');
    }
  };

  play = (uri: string, name: string, type: 'audio' | 'video') => {
    return this._loadNewPlaybackInstance(true, { name, uri, type });
  };

  stop = () => {
    if (this.playbackInstance) {
      this.playbackInstance
        .getStatusAsync()
        .then((status) => {
          if (status.isLoaded) {
            this.statusListeners.forEach((listener) => listener(status));
          }

          return this.playbackInstance?.stopAsync();
        })
        .catch(() => this.playbackInstance?.stopAsync())
        .catch(() => this.playbackInstance?.stopAsync())
        .catch(() => {});
    }
  };

  toggleMute = () => {
    if (this.playbackInstance) {
      this.playbackInstance.setIsMutedAsync(!this.state.muted);
    }
  };

  toggleLoop = () => {
    if (this.playbackInstance) {
      this.playbackInstance.setIsLoopingAsync(
        this.state.loopingType !== LOOPING_TYPE_ONE
      );
    }
  };

  volume = (value: number) => {
    if (this.playbackInstance) {
      this.playbackInstance.setVolumeAsync(value);
    }
  };

  private _setRateAndPitch = async (
    rate: number,
    shouldCorrectPitch: boolean
  ) => {
    if (this.playbackInstance) {
      try {
        await this.playbackInstance.setRateAsync(rate, shouldCorrectPitch);
      } catch (error) {
        // Rate changing could not be performed, possibly because the client's Android API is too old.
      }
    }
  };

  rate = async (value: number) => {
    this._setRateAndPitch(value * RATE_SCALE, this.state.shouldCorrectPitch);
  };

  togglePitchCorrection = async () => {
    this._setRateAndPitch(this.state.rate, !this.state.shouldCorrectPitch);
  };

  startSeeking = () => {
    if (this.playbackInstance && !this.isSeeking) {
      this.isSeeking = true;
      this.shouldPlayAtEndOfSeek = this.state.shouldPlay;
    }

    if (this.playbackInstance && !this.isSeeking) {
      this.isSeeking = true;
      this.shouldPlayAtEndOfSeek = this.state.shouldPlay;
      this.playbackInstance.pauseAsync();
    }
  };

  seek = async (next: number) => {
    if (this.playbackInstance && this.state.playbackInstanceDuration !== null) {
      this.isSeeking = false;

      const previous = this.state.playbackInstancePosition;

      if (previous) {
        this.seekListeners.forEach((listener) => listener(previous, next));
      }

      if (this.shouldPlayAtEndOfSeek) {
        await this.playbackInstance.playFromPositionAsync(next);
      } else {
        await this.playbackInstance.setPositionAsync(next);
      }
    }
  };

  _getSeekSliderPosition() {
    if (
      this.playbackInstance &&
      this.state.playbackInstancePosition != null &&
      this.state.playbackInstanceDuration != null
    ) {
      return (
        this.state.playbackInstancePosition /
        this.state.playbackInstanceDuration
      );
    }
    return 0;
  }

  _getMMSSFromMillis(millis: number) {
    const totalSeconds = millis / 1000;
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor(totalSeconds / 60);

    const padWithZero = (number: number) => {
      const string = number.toString();
      if (number < 10) {
        return '0' + string;
      }
      return string;
    };
    return padWithZero(minutes) + ':' + padWithZero(seconds);
  }

  _getTimestamp() {
    if (
      this.playbackInstance &&
      this.state.playbackInstancePosition != null &&
      this.state.playbackInstanceDuration != null
    ) {
      return `${this._getMMSSFromMillis(
        this.state.playbackInstancePosition
      )} / ${this._getMMSSFromMillis(this.state.playbackInstanceDuration)}`;
    }
    return '';
  }

  togglePoster = () => {
    this.setState((state) => ({ ...state, poster: !state.poster }));
  };

  toggleControls = () => {
    this.setState((state) => ({
      ...state,
      useNativeControls: !state.useNativeControls,
    }));
  };

  toggleFullscreen = () => {
    try {
      this.video?.presentFullscreenPlayer();
    } catch (error) {
      console.log(error);
    }
  };

  toggleSpeaker = () => {
    this.setState(
      (state) => {
        return { throughEarpiece: !state.throughEarpiece };
      },
      () =>
        Audio.setAudioModeAsync({
          allowsRecordingIOS: false,
          interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
          playsInSilentModeIOS: true,
          shouldDuckAndroid: true,
          interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
          playThroughEarpieceAndroid: this.state.throughEarpiece,
        })
    );
  };

  render() {
    return this.props.children;
  }
}
