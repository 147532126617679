import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { nativeApplicationVersion, nativeBuildVersion } from 'expo-application';
import { setStringAsync } from 'expo-clipboard';
import Constants from 'expo-constants';
import { deviceName, deviceYearClass, isDevice } from 'expo-device';
import {
  getPermissionsAsync,
  requestPermissionsAsync,
} from 'expo-notifications';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import {
  Button,
  DataTable,
  Dialog,
  List,
  Paragraph,
  Portal,
  Switch,
  Text,
  ThemeProvider,
  Title,
  useTheme,
} from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';
import { GoogleIcon } from '../authentication/logins/Google';
import { BackHeader } from '../components/BackHeader';
import { API_ENDPOINT, DOMAIN } from '../config';
import { defineTranslations, i18n } from '../locale';
import {
  PASTEL_PINK,
  PASTEL_PURPLE,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
} from '../theming';
import { useThemedColor } from '../utils/useThemedColor';
import {
  AuthProvider,
  Consent,
  RemoteAccountProfile,
  useProfile,
  useUpdateProfile,
} from './useUserProfile';

defineTranslations({
  en: {
    app: {
      settings: {
        title: 'Settings',

        actions: {
          new_picture: 'Upload new profile picture',
          save: 'Save changes',
          delete: 'Delete account',
          connection_test: 'Run connection test',
          debug_information: 'Debug information (English)',
        },

        delete_manually:
          'We have temporary suspended automatic deletion of accounts in order to preserve artist accounts. However, you can still exercise your right by emailing info@soundersmusic.com.',

        notifications: {
          title: 'Notifications',
        },

        connections: {
          title: 'Connected',
          description:
            "These social networks can be used to login to your account. At this moment you can't disconnect, reconnect or connect.",

          active: 'Connected',
          inactive: 'Not connected',
        },

        information: {
          title: 'Information',
          description:
            'Some information about this application. We might ask for this when you request support.',
        },

        fields: {
          shared_track_rating: {
            title: 'Shared track rating',
            description:
              "When someone who you've invited to listen to a track rates that track, so you can compare your rating with theirs.",
          },

          new_event: {
            title: 'New event',
            description:
              'When there is a new event available, so you can discover new music or participate in limited-time events.',
          },

          new_track: {
            title: 'New track from favourite artists',
            description:
              'When an artist you rated highly in the past releases a new track, so you are one of the first to give feedback on their new creation.',
          },
        },
      },
    },
  },

  nl: {
    app: {
      settings: {
        title: 'Instellingen',

        actions: {
          new_picture: 'Wijzig profiel plaatje',
          save: 'Opslaan',
          delete: 'Account verwijderen',
          connection_test: 'Verbinding testen',
          debug_information: 'Debug informatie (Engels)',
        },

        delete_manually:
          'We hebben tijdelijk het automatisch verwijderen van accounts toestaan uitgezet om te voorkomen dat artiesten per ongeluk hun account verwijderen. Je kan je aanvraag indienen op info@soundersmusic.com.',

        notifications: {
          title: 'Notificaties',
        },

        connections: {
          title: 'Social authenticatie',
          description:
            'Deze sociale netwerken kan je gebruiken om in te loggen. Je kan op dit moment sociale netwerken niet ontkoppelen of opnieuw koppelen.',

          active: 'Verbonden',
          inactive: 'Niet verbonden',
        },

        information: {
          title: 'Informatie',
          description:
            'Deze informatie gaat specifiek over jouw acccount en installatie. Als je om hulp vraagt kunnen wij vragen naar deze informatie.',
        },

        fields: {
          shared_track_rating: {
            title: 'Gedeelde track rating',
            description:
              'Wanneer iemand die je hebt uitgenodigd een track te beoordelen die de track beoordeeld, zodat je de beoordeling kan vergelijken met je eigen.',
          },

          new_event: {
            title: 'Nieuw evenement',
            description:
              'Waneer er nieuwe evenementen beschikbaar zijn, zodat je nieuwe muziek kan ontdekken of kan meedoen aan tijd gelimiteerde evenementen.',
          },

          new_track: {
            title: 'Nieuwe track van je favouriete artiesten',
            description:
              'Wanneer een artiest die je in het verleden hoog hebt beoordeeld een nieuwe track instuurt, zodat jij als een van de eersten feedback kan geven op hun nieuwste creatie.',
          },
        },
      },
    },
  },
});

export function SettingsScreen() {
  const { data: profile } = useProfile();
  const title = useThemedColor(PRIMARY_DARK, PASTEL_PINK);

  return (
    <ThemeProvider
      theme={useTheme({
        colors: { accent: useThemedColor(PASTEL_PURPLE, PASTEL_PINK) },
      })}
    >
      <View style={styles.wrapper}>
        <ScrollView
          style={styles.scroller}
          contentContainerStyle={styles.scrollContent}
        >
          <Text
            variant="displayMedium"
            style={{
              color: title,
              maxWidth: 250,
              marginHorizontal: 20,
              marginBottom: 32,
              includeFontPadding: false,
              display: 'flex',
            }}
          >
            {i18n.translate('app.settings.title')}
          </Text>

          <NotificationsCard />
          <SocialConnectionsCard authProviders={profile?.auth_providers} />
          <DebugCard profile={profile} />
        </ScrollView>

        <View
          style={{
            position: 'absolute',
            maxWidth: 600,
            alignSelf: 'center',
            width: '100%',
          }}
        >
          <BackHeader />
        </View>
      </View>
    </ThemeProvider>
  );
}

function NotificationsCard() {
  const cardColor = useThemedColor(`${PRIMARY_DARK}0D`, `${PRIMARY_LIGHT}0D`);
  const titleColor = useThemedColor(`${PRIMARY_DARK}70`, `${PASTEL_PINK}94`);
  const color = useThemedColor(`${PRIMARY_DARK}70`, '#FFFFFFAB');

  const {
    profileRef,
    mutation: { mutateAsync: updateProfile, isLoading: isUpdating },
    prepare,
  } = useUpdateProfile();

  const profile = profileRef.current;
  const consent = useMemo(() => {
    if (!profile?.consent) {
      return {} as Record<Consent, true>;
    }

    return profile.consent.reduce(
      (result, key) => ({ ...result, [key]: true }),
      {} as Record<Consent, true>
    );
  }, [profileRef, profile?.consent]);

  const toggleConsent = useCallback(
    (key: Consent) => {
      if (!profile) {
        return;
      }

      // Ask for push permission if necessary
      if (!consent[key] && key.includes('.push')) {
        getPermissionsAsync()
          .then((value) => {
            if (value.granted) {
              return true;
            }

            if (value.canAskAgain) {
              return requestPermissionsAsync({
                android: {},
                ios: {
                  allowAlert: true,
                  allowBadge: true,
                  allowSound: true,
                  allowAnnouncements: true,
                },
              }).then((value) => value.granted);
            }

            // TODO show error message
          })
          .catch(() => {});
      }

      const nextConsent: Consent[] = consent[key]
        ? (Object.keys(consent) as Consent[]).filter((c) => c !== key)
        : (Object.keys(consent) as Consent[]).concat([key]);

      if (consent[key]) {
        delete consent[key];
      } else {
        consent[key] = true;
      }

      const patch = prepare({ next: { ...profile, consent: nextConsent } });

      if (patch) {
        updateProfile({ patch });
      }
    },
    [profile, consent]
  );

  return (
    <View
      style={{
        backgroundColor: cardColor,
        width: '100%',
        borderRadius: 26,
        paddingVertical: 21,
        marginBottom: 15,
      }}
    >
      <Text
        variant="titleLarge"
        style={{
          color: titleColor,
          fontSize: 30,
          letterSpacing: 30 / 20,
          lineHeight: 34,
          maxWidth: 250,
          marginBottom: 32,
          marginHorizontal: 21,
          textAlignVertical: 'center',
          includeFontPadding: false,
          display: 'flex',
        }}
      >
        {i18n.translate('app.settings.notifications.title')}
      </Text>

      <View style={styles.cardSectionFirst}>
        <Text variant="titleLarge" style={{ display: 'flex' }}>
          {i18n.translate('app.settings.fields.shared_track_rating.title')}
        </Text>
        <Text
          variant="bodyLarge"
          style={{
            lineHeight: 18,
            fontSize: 14,
            color,
            marginTop: 4,
            display: 'flex',
          }}
        >
          {i18n.translate(
            'app.settings.fields.shared_track_rating.description'
          )}
        </Text>
      </View>

      <List.Item
        style={styles.option}
        disabled={isUpdating}
        onPress={() => toggleConsent('shared-track-rating.push.2020-08-15')}
        right={() => (
          <Switch
            disabled={isUpdating}
            onValueChange={
              Platform.OS === 'web'
                ? () => {}
                : () => toggleConsent('shared-track-rating.push.2020-08-15')
            }
            value={!!consent['shared-track-rating.push.2020-08-15']}
            pointerEvents="none"
          />
        )}
        title="Push notification"
      />
      <List.Item
        style={styles.option}
        onPress={() => toggleConsent('shared-track-rating.email.2020-08-15')}
        right={() => (
          <Switch
            disabled={isUpdating}
            onValueChange={
              Platform.OS === 'web'
                ? () => {}
                : () => toggleConsent('shared-track-rating.email.2020-08-15')
            }
            value={!!consent['shared-track-rating.email.2020-08-15']}
          />
        )}
        title="Email"
      />

      <View style={styles.cardSection}>
        <Text variant="titleLarge" style={{ display: 'flex' }}>
          {i18n.translate('app.settings.fields.new_event.title')}
        </Text>
        <Text
          variant="bodyLarge"
          style={{
            lineHeight: 18,
            fontSize: 14,
            color,
            marginTop: 4,
            display: 'flex',
          }}
        >
          {i18n.translate('app.settings.fields.new_event.description')}
        </Text>
      </View>

      <List.Item
        style={styles.option}
        disabled={isUpdating}
        onPress={() => toggleConsent('new-event.push.2020-08-15')}
        right={() => (
          <Switch
            disabled={isUpdating}
            onValueChange={
              Platform.OS === 'web'
                ? () => {}
                : () => toggleConsent('new-event.push.2020-08-15')
            }
            value={!!consent['new-event.push.2020-08-15']}
          />
        )}
        title="Push notification"
      />
      <List.Item
        style={styles.option}
        disabled={isUpdating}
        onPress={() => toggleConsent('new-event.email.2020-08-15')}
        right={() => (
          <Switch
            disabled={isUpdating}
            onValueChange={
              Platform.OS === 'web'
                ? () => {}
                : () => toggleConsent('new-event.email.2020-08-15')
            }
            value={!!consent['new-event.email.2020-08-15']}
          />
        )}
        title="Email"
      />

      <View style={styles.cardSection}>
        <Text variant="titleLarge" style={{ display: 'flex' }}>
          {i18n.translate('app.settings.fields.new_track.title')}
        </Text>
        <Text
          variant="bodyLarge"
          style={{
            lineHeight: 18,
            fontSize: 14,
            color,
            marginTop: 4,
            display: 'flex',
          }}
        >
          {i18n.translate('app.settings.fields.new_track.description')}
        </Text>
      </View>

      <List.Item
        style={styles.option}
        disabled={isUpdating}
        onPress={() => toggleConsent('new-track.push.2020-08-15')}
        right={() => (
          <Switch
            disabled={isUpdating}
            onValueChange={
              Platform.OS === 'web'
                ? () => {}
                : () => toggleConsent('new-track.push.2020-08-15')
            }
            value={!!consent['new-track.push.2020-08-15']}
          />
        )}
        title="Push notification"
      />
      <List.Item
        style={styles.option}
        disabled={isUpdating}
        onPress={() => toggleConsent('new-track.email.2020-08-15')}
        right={() => (
          <Switch
            disabled={isUpdating}
            onValueChange={
              Platform.OS === 'web'
                ? () => {}
                : () => toggleConsent('new-track.email.2020-08-15')
            }
            value={!!consent['new-track.email.2020-08-15']}
          />
        )}
        title="Email"
      />
    </View>
  );
}

function SocialConnectionsCard({
  authProviders,
}: {
  authProviders?: AuthProvider[];
}) {
  const cardColor = useThemedColor(`${PRIMARY_DARK}0D`, `${PRIMARY_LIGHT}0D`);
  const titleColor = useThemedColor(`${PRIMARY_DARK}70`, `${PASTEL_PINK}94`);
  const color = useThemedColor(`${PRIMARY_DARK}70`, '#FFFFFFAB');

  return (
    <View
      style={{
        backgroundColor: cardColor,
        width: '100%',
        borderRadius: 26,
        paddingVertical: 21,
        marginBottom: 15,
      }}
    >
      <Text
        variant="titleLarge"
        style={{
          color: titleColor,
          fontSize: 30,
          letterSpacing: 30 / 20,
          lineHeight: 34,
          maxWidth: 250,
          marginBottom: 32,
          marginHorizontal: 21,
          textAlignVertical: 'center',
          includeFontPadding: false,
          display: 'flex',
        }}
      >
        {i18n.translate('app.settings.connections.title')}
      </Text>

      <Text
        variant="bodyLarge"
        style={[
          {
            lineHeight: 18,
            fontSize: 14,
            color,
            paddingHorizontal: 21,
            marginBottom: 32,
            display: 'flex',
          },
        ]}
      >
        {i18n.translate('app.settings.connections.description')}
      </Text>

      {ALL_PROVIDERS.map((provider) => (
        <Connected
          key={provider}
          provider={provider}
          connected={(authProviders || []).includes(provider)}
        />
      ))}
    </View>
  );
}

const ALL_PROVIDERS: AuthProvider[] = [
  'facebook',
  'spotify',
  'apple',
  'google',
];

function Connected({
  provider,
  connected,
}: {
  provider: AuthProvider;
  connected: boolean;
}) {
  const color = useThemedColor(PRIMARY_DARK, '#FFFFFF');
  const activeColor = useThemedColor(PRIMARY_DARK, '#FFFFFF');
  const inactiveColor = useThemedColor(`${PRIMARY_DARK}70`, '#FFFFFFAB');

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 12,
        paddingHorizontal: 21,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {renderIcon(provider)}
        <Text
          variant="labelLarge"
          style={{ color, marginLeft: 8, fontWeight: 'bold', display: 'flex' }}
        >
          {provider[0].toLocaleUpperCase() + provider.slice(1)}
        </Text>
      </View>
      <Text
        variant="labelLarge"
        style={{
          color: connected ? activeColor : inactiveColor,
          display: 'flex',
        }}
      >
        {connected
          ? i18n.translate('app.settings.connections.active')
          : i18n.translate('app.settings.connections.inactive')}
      </Text>
    </View>
  );
}

function DebugCard({ profile }: { profile?: RemoteAccountProfile | null }) {
  const cardColor = useThemedColor(`${PRIMARY_DARK}0D`, `${PRIMARY_LIGHT}0D`);
  const titleColor = useThemedColor(`${PRIMARY_DARK}70`, `${PASTEL_PINK}94`);
  const color = useThemedColor(`${PRIMARY_DARK}70`, '#FFFFFFAB');

  const navigation = useNavigation();
  const gotoConnectionTest = useCallback(
    () => navigation.navigate('Root', { screen: 'TestConnection' }),
    [navigation]
  );

  const [showingDebugDialog, setShowDebugDialog] = useState(false);
  const showDebugDialog = useCallback(
    () => setShowDebugDialog(true),
    [setShowDebugDialog]
  );
  const hideDebugDialog = useCallback(
    () => setShowDebugDialog(false),
    [setShowDebugDialog]
  );

  return (
    <View
      style={{
        backgroundColor: cardColor,
        width: '100%',
        borderRadius: 26,
        paddingVertical: 21,
        marginBottom: 15,
      }}
    >
      <Text
        variant="titleLarge"
        style={{
          color: titleColor,
          fontSize: 30,
          letterSpacing: 30 / 20,
          lineHeight: 34,
          maxWidth: 250,
          marginBottom: 32,
          marginHorizontal: 21,
          textAlignVertical: 'center',
          includeFontPadding: false,
          display: 'flex',
        }}
      >
        {i18n.translate('app.settings.information.title')}
      </Text>

      <Text
        variant="bodyLarge"
        style={[
          {
            lineHeight: 18,
            fontSize: 14,
            color,
            paddingHorizontal: 21,
            marginBottom: 32,
            display: 'flex',
          },
        ]}
      >
        {i18n.translate('app.settings.information.description')}
      </Text>
      <List.Item
        style={styles.option}
        title="Endpoint"
        description={API_ENDPOINT}
      />

      <List.Item
        style={styles.option}
        title="Profile"
        description={(profile?._links.self.href || '')
          .replace(API_ENDPOINT ?? '', '')
          .replace('/users/', '')
          .replace('/profile', '')}
      />

      <List.Item
        style={styles.option}
        title="Account E-mail"
        description={profile?.email}
      />

      <View style={{ marginRight: 'auto', marginTop: 21 }}>
        <Button
          onPress={showDebugDialog}
          icon="bug"
          mode="contained-tonal"
          style={{ marginHorizontal: 21 }}
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
        >
          {i18n.translate('app.settings.actions.debug_information')}
        </Button>
      </View>

      <View style={{ marginRight: 'auto', marginTop: 21 }}>
        <Button
          onPress={gotoConnectionTest}
          icon="bug"
          mode="contained-tonal"
          style={{ marginHorizontal: 21 }}
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
        >
          {i18n.translate('app.settings.actions.connection_test')}
        </Button>
      </View>

      <Portal>
        <Dialog
          visible={showingDebugDialog}
          onDismiss={hideDebugDialog}
          style={{
            alignSelf: 'center',
            maxWidth: 450,
            width: '100%',
            maxHeight: '100%',
          }}
        >
          <DebugDialogContent onDismiss={hideDebugDialog} />
        </Dialog>
      </Portal>
    </View>
  );
}

function renderIcon(provider: AuthProvider): JSX.Element | null {
  switch (provider) {
    case 'spotify': {
      return (
        <MaterialCommunityIcons
          size={28 + 8}
          name="spotify"
          style={{
            backgroundColor: '#fff',
            borderRadius: 18,
            padding: 0,
            overflow: 'hidden',
          }}
          color="#1FD760"
        />
      );
    }

    case 'facebook': {
      return (
        <MaterialCommunityIcons
          size={28}
          name="facebook"
          style={{
            backgroundColor: '#347AE5',
            borderRadius: 18,
            padding: 4,
            overflow: 'hidden',
          }}
          color="#fff"
        />
      );
    }

    case 'apple': {
      return (
        <MaterialCommunityIcons
          size={24}
          name="apple"
          style={{
            backgroundColor: '#000',
            borderRadius: 18,
            padding: 6,
            overflow: 'hidden',
          }}
          color="#fff"
        />
      );
    }

    case 'google': {
      return (
        <View
          style={{
            backgroundColor: '#fff',
            borderRadius: 18,
            padding: 0,
            overflow: 'hidden',
          }}
        >
          <GoogleIcon size={36} color="#fff" />
        </View>
      );
    }
  }

  return null;
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    overflow: 'hidden',
    height: '100%',
  },

  scroller: {
    width: '100%',
    maxWidth: '100%',
    flex: 1,
  },

  scrollContent: {
    paddingHorizontal: 15,
    paddingTop: 128,
    maxWidth: 600,
    width: '100%',
    alignSelf: 'center',
  },

  cardSectionFirst: { marginHorizontal: 21, marginBottom: 16 },
  cardSection: { marginHorizontal: 21, marginTop: 32, marginBottom: 16 },

  option: { paddingHorizontal: 4 },
});

function DebugDialogContent({ onDismiss }: { onDismiss: () => void }) {
  const [copied, setCopied] = useState(false);

  const information = useMemo((): [string, string][] => {
    return [
      ['Device', isDevice ? 'Yes' : 'No'],
      ['Device name', deviceName || 'Unknown'],
      ['Device class', deviceYearClass?.toString() || '-'],
      ['Runtime version', Constants.expoRuntimeVersion || '-'],
      ['App version', nativeApplicationVersion || '-'],
      ['App build', nativeBuildVersion || '-'],
      ['App owner', Constants.appOwnership || '-'],
      ['Platform', Platform.OS || '-'],
      [
        'Platform model',
        Platform.select({ ios: Constants.platform?.ios?.model }) || '-',
      ],
      ['Platform version', Platform.Version?.toString() || '-'],
      ['System version', Constants.systemVersion?.toString() || '-'],
      ['', ''],
      ['Manifest name', Constants.manifest?.name || '-'],
      ['Release channel', Constants.manifest?.releaseChannel || 'none'],
      ['Release id', Constants.manifest?.releaseId || '-'],
      ['Release revision', Constants.manifest?.revisionId || '-'],
      ['Release version', Constants.manifest?.version || '-'],
      ['Release sdk', Constants.manifest?.sdkVersion || '-'],
      ['', ''],
      [
        'App domain',
        (DOMAIN || 'none')
          .replace('https://', '')
          .replace('http://', '')
          .replace('/api', ''),
      ],
      [
        'API domain',
        (API_ENDPOINT ?? '')
          .replace('https://', '')
          .replace('http://', '')
          .replace('/api', ''),
      ],
    ];
  }, []);

  const isMounted = useIsMounted();

  const copyToClipboard = useCallback(() => {
    const leftSize = Math.max(...information.map((row) => row[0]?.length || 0));

    setStringAsync(
      information
        .map((row) =>
          row[0] === '' ? '' : `${row[0].padEnd(leftSize, ' ')}\t\t${row[1]}`
        )
        .join('\n')
    )
      .then(() => isMounted.current && setCopied(true))
      .catch(() => {});
  }, [information]);

  return (
    <Fragment>
      <Dialog.Title>Device and App information</Dialog.Title>
      <Dialog.Content>
        <Paragraph>
          In order to resolve any issues encountered, there is information that
          will really help us track down the root cause. We don't store or
          collect this automatically, so we kindly request you to copy the
          content and provide it when reporting a problem.
        </Paragraph>
      </Dialog.Content>
      <Dialog.ScrollArea style={{ paddingHorizontal: 0 }}>
        <ScrollView style={{ maxHeight: 300 }}>
          <DataTable>
            {information.map((row, index, self) => (
              <DataTable.Row key={index}>
                {row.map((cell, i) => (
                  <DataTable.Cell
                    numeric={i > 0}
                    key={i}
                    style={{
                      flexShrink: i === 1 ? 0 : 1,
                      paddingHorizontal: 10,
                    }}
                  >
                    {cell}
                  </DataTable.Cell>
                ))}
              </DataTable.Row>
            ))}
          </DataTable>
        </ScrollView>
      </Dialog.ScrollArea>
      <Dialog.Actions
        style={{ paddingHorizontal: 16, paddingBottom: 12, paddingTop: 12 }}
      >
        <Button
          icon={copied ? 'check' : 'content-copy'}
          onPress={copyToClipboard}
          style={{ marginRight: 'auto' }}
          mode="contained"
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
        >
          {copied ? 'Copied' : 'Copy to clipboard'}
        </Button>
        <Button
          icon="close"
          onPress={onDismiss}
          mode="text"
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
        >
          Close
        </Button>
      </Dialog.Actions>
    </Fragment>
  );
}
