import { useIsFocused } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { useURL } from 'expo-linking';
import { default as React, useCallback, useMemo } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  Platform,
  StatusBar,
  View,
} from 'react-native';
import {
  ActivityIndicator,
  Appbar,
  Avatar,
  Button,
  Card,
  Surface,
  Text,
  useTheme,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useProfile, useThumbnail } from '../account/useUserProfile';
import { NetworkError } from '../components/NetworkError';
import { PressableSurface } from '../components/PressableSurface';
import { useCompetitions } from '../events/useCompetitions';
import { ApiEvent } from '../events/useEvent';
import { useEvents } from '../events/useEvents';
import { usePrivateConfiguration } from '../hooks/usePrivateConfiguration';
import { defineTranslations, i18n } from '../locale';
import { useOpenMenu } from '../navigation/MenuActionContext';
import { resetToPath } from '../navigation/utils';
import {
  DARK_PURPLE,
  PASTEL_PINK,
  PASTEL_PURPLE,
  PRIMARY_DARK,
} from '../theming';
import { useThemedColor } from '../utils/useThemedColor';
import { DiscoverEventCard } from './DiscoverEventCard';
import { DiscoverShareCard } from './DiscoverShareCard';
import { GrotePrijsVanNederlandCard } from './GrotePrijsVanNederlandCard';
import { PoweredBy } from './PoweredBy';
import { SkeletonCards } from './SkeletonCard';

defineTranslations({
  en: {
    app: {
      discovery: {
        shared_link_limitation:
          'Note: you can only vote on the same act once each round.',

        actions: {
          goto_menu: 'Open menu',
          goto_event: 'Start listening',
          goto_ranking: 'Ranking',
          goto_vote: 'Vote',
          reopen: '(Re)-Open shared link',
        },
      },
    },
  },

  nl: {
    app: {
      discovery: {
        shared_link_limitation:
          'Let op: je kan slechts één keer per ronde op dezelfde track stemmen.',

        actions: {
          goto_menu: 'Open menu',
          goto_event: 'Begin met luisteren',
          goto_ranking: 'Ranking',
          goto_vote: 'Stem',
          reopen: 'Gedeelde link (opnieuw) openen',
        },
      },
    },
  },
});

type SkeletonData = { type: 'skeleton' };
type ShareData = { type: 'share'; href: string };
type EventData = { type: 'event'; href: string; title: string; index: number };
type UrlData = { type: 'url' };
type SpecialEventData = {
  type: 'gpvnl';
  href: string;
  title: string;
} & ApiEvent;

type PoweredByData = { type: 'powered-by' };

type DiscoveryData =
  | SkeletonData
  | ShareData
  | EventData
  | SpecialEventData
  | PoweredByData
  | UrlData;

export function DiscoveryScreen() {
  const focused = useIsFocused();
  const { dark } = useTheme();

  const {
    isLoading: isLoadingConfiguration,
    error: configurationError,
    refetch: refetchConfiguration,
  } = usePrivateConfiguration({ enabled: focused });

  const {
    data: eventsData,
    isLoading: isLoadingEvents,
    error: eventsError,
    refetch: refetchEvents,
  } = useEvents({ enabled: focused });

  const {
    data: competitionsData,
    isLoading: isLoadingCompetitions,
    error: competitionsError,
    refetch: refetchCompetitions,
  } = useCompetitions({ enabled: focused });

  const {
    isFetched,
    isLoading: isLoadingProfile,
    error: profileError,
    refetch: refetchProfile,
  } = useProfile({ enabled: focused });

  const refetchAll = useCallback(() => {
    return refetchConfiguration().then(() =>
      Promise.all([refetchProfile(), refetchEvents(), refetchCompetitions()])
    );
  }, [
    refetchConfiguration,
    refetchProfile,
    refetchEvents,
    refetchCompetitions,
  ]);

  const isLoading =
    isLoadingEvents ||
    isLoadingConfiguration ||
    isLoadingProfile ||
    isLoadingCompetitions;
  const error =
    eventsError || configurationError || profileError || competitionsError;

  const data: DiscoveryData[] = useMemo(() => {
    if (isLoading) {
      return [{ type: 'skeleton' }];
    }

    if (!eventsData) {
      return [];
    }

    const {
      events: { _index, _links },
    } = eventsData;

    const cards: DiscoveryData[] = [];
    if (_links?.shared_tracks) {
      cards.push({ type: 'share', href: _links.shared_tracks.href });
    }

    _index.forEach((event, index) => {
      cards.push({
        type: 'event',
        href: event.href,
        title: event.title,
        index,
      });
    });

    if (competitionsData) {
      const competitions = competitionsData.competitions._embedded;
      competitions.forEach(({ competition }) => {
        if (
          !competition._links.self.href.endsWith(
            '1ce011be-d7a0-4daa-80f7-c919fea4d9d8'
          )
        ) {
          return;
        }

        competition._embedded.forEach(({ event }) => {
          cards.splice(
            cards.findIndex((event) => event.type === 'event') + 1,
            0,
            {
              type: 'gpvnl',
              href: event._links.self.href,
              title: event._links.self.title,
              event,
            }
          );
        });
      });
    }

    cards.splice(2, 0, { type: 'url' });
    cards.push({ type: 'powered-by' });

    return cards;
  }, [isLoading, eventsData]);

  if (!isFetched) {
    if (isLoading) {
      return (
        <View style={{ position: 'relative', flex: 1 }}>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <ActivityIndicator size="large" />
          </View>
          <View
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              justifyContent: 'center',
            }}
            pointerEvents="box-none"
          >
            <Header />
          </View>
        </View>
      );
    }

    if (error) {
      return (
        <View style={{ position: 'relative', flex: 1 }}>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              maxWidth: 346 * 1.2 + 16,
              alignSelf: 'center',
              marginHorizontal: 'auto',
            }}
          >
            <NetworkError
              error={error}
              loading={isLoading}
              retry={refetchAll}
            />
          </View>
          <View
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              justifyContent: 'center',
            }}
            pointerEvents="box-none"
          >
            <Header />
          </View>
        </View>
      );
    }
    return null;
  }

  return (
    <View style={{ position: 'relative', flex: 1 }}>
      <FlatList
        nativeID="scroller"
        data={data}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        style={{ flex: 1 }}
        contentContainerStyle={{
          maxWidth: 346 * 1.2 + 16,
          alignSelf: 'center',
          width: '100%',

          paddingTop: 144 - 48,
          paddingBottom: 124,
          paddingHorizontal: 16,
        }}
      />
      <LinearGradient
        colors={
          dark
            ? [`${PRIMARY_DARK}00`, `${PRIMARY_DARK}33`]
            : [`${PRIMARY_DARK}00`, `${PRIMARY_DARK}1A`]
        }
        style={{ width: '100%', height: 72, position: 'absolute', bottom: 0 }}
        pointerEvents="none"
      />
      <View
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          justifyContent: 'center',
        }}
        pointerEvents="box-none"
      >
        <Header />
      </View>
    </View>
  );
}

function keyExtractor(item: DiscoveryData) {
  switch (item.type) {
    case 'skeleton': {
      return 'skeleton';
    }
    case 'powered-by': {
      return 'powered-by';
    }
    case 'url': {
      return 'url';
    }
    default: {
      return item.href;
    }
  }
}

function renderItem({ item }: ListRenderItemInfo<DiscoveryData>) {
  switch (item.type) {
    case 'skeleton': {
      return <SkeletonCards />;
    }

    case 'share': {
      return <DiscoverShareCard href={item.href} />;
    }

    case 'url': {
      return <AppLinkUrlCard />;
    }

    case 'gpvnl': {
      return (
        <GrotePrijsVanNederlandCard event={item.event} progress={undefined} />
      );
    }

    case 'event': {
      return (
        <DiscoverEventCard
          href={item.href}
          title={item.title}
          index={item.index}
        />
      );
    }

    case 'powered-by': {
      return <PoweredBy />;
    }
  }

  return null;
}

function AppLinkUrlCard() {
  const url = useURL();
  const textColor = useThemedColor(PASTEL_PURPLE, PASTEL_PINK);

  if (Platform.OS === 'web' && !__DEV__) {
    return null;
  }

  if (!url || (!url.includes('/share/') && !__DEV__)) {
    return null;
  }

  return (
    <Surface
      style={{
        maxWidth: '100%',
        width: '100%',
        marginTop: 8,
        marginBottom: 24,
        borderRadius: 12,
        padding: 12,
      }}
      elevation={1}
    >
      <Button
        onPress={() => resetToPath(url)}
        mode="outlined"
        textColor={textColor}
        style={{ borderRadius: 12 }}
      >
        {i18n.translate('app.discovery.actions.reopen')}
      </Button>
      <Text
        style={{ flex: 1, maxWidth: 300, marginTop: 10, opacity: 0.8 }}
        variant="labelSmall"
      >
        {i18n.translate('app.discovery.shared_link_limitation')}
      </Text>
    </Surface>
  );
}

export function Header() {
  const { top } = useSafeAreaInsets();
  const onGotoProfile = useOpenMenu();

  const { data: profile } = useProfile();
  const { uri, size } = useThumbnail();
  const { dark } = useTheme();

  const initial = (profile?.name ?? profile?.email ?? '')[0];

  return (
    <Appbar.Header
      style={{
        zIndex: 1,
        maxWidth: 800,
        margin: 'auto',
        width: '100%',

        backgroundColor: 'transparent',
        elevation: 0,
        paddingHorizontal: 36,
        height: 52 + 36,
      }}
      statusBarHeight={Platform.select({
        ios: StatusBar.currentHeight || top || 20,
        default: undefined,
      })}
      pointerEvents="box-none"
    >
      <Card
        onPress={onGotoProfile}
        style={{
          borderRadius: 52 / 2,
          width: 52,
          height: 52,
          overflow: 'hidden',
        }}
        accessibilityLabel={i18n.translate('app.discovery.actions.goto_menu')}
      >
        {uri ? (
          <Avatar.Image
            source={{
              uri,
              width: size,
              height: size,
            }}
            size={52}
            style={{
              backgroundColor: dark ? PASTEL_PINK : '#121212',
              borderRadius: 52 / 2,
            }}
          />
        ) : (
          <Avatar.Text
            size={52}
            style={{
              backgroundColor: dark ? PASTEL_PINK : '#121212',
              borderRadius: 52 / 2,
            }}
            color={dark ? DARK_PURPLE : '#ffffff'}
            label={(initial ?? '').toLocaleUpperCase()}
          />
        )}
      </Card>
    </Appbar.Header>
  );
}
