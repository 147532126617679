import { Platform } from 'react-native';
import {
  configureFonts,
  MD3DarkTheme,
  MD3LightTheme,
  adaptNavigationTheme,
  MD3Theme,
} from 'react-native-paper';
import {
  DarkTheme as NavDarkTheme,
  DefaultTheme as NavLightTheme,
} from '@react-navigation/native';

export const PASTEL_BLUE = '#CBF6FF';
export const PASTEL_GREEN = '#DEF3D4';
export const PASTEL_PURPLE = '#775AA0';
export const PASTEL_PINK = '#D8ADF9';
export const DARK_PURPLE = '#1D0F34';
export const BACKGROUND_DARK = '#10081C';

// '600': '#160C28',
// '700': '#10081C',
// '800': '#090510',
// '900': '#030105'

export const PRIMARY_DARK = DARK_PURPLE;
export const PRIMARY_LIGHT = PASTEL_PURPLE;
export const ACCENT = PASTEL_BLUE;

/*
export const FONTS = {
  web: {
    regular: {
      fontFamily: '"Helvetica Neue", Poppins_400Regular, Arial, sans-serif',
      fontWeight: '400' as const,
    },
    medium: {
      fontFamily: '"Helvetica Neue", Poppins_500Medium, Arial, sans-serif',
      fontWeight: '500' as const,
    },
    light: {
      fontFamily: '"Helvetica Neue", Poppins_300Light, Arial, sans-serif',
      fontWeight: '300' as const,
    },
    thin: {
      fontFamily: '"Helvetica Neue", Poppins_100Thin, Arial, sans-serif',
      fontWeight: '100' as const,
    },
    // @ts-ignore
    semibold: {
      fontFamily: '"Helvetica Neue", Poppins_600SemiBold, Arial, sans-serif',
      fontWeight: '600' as const,
    },
    bold: {
      fontFamily: '"Helvetica Neue", Poppins_700Bold, Arial, sans-serif',
      fontWeight: 'bold' as const,
    },
    extrabold: {
      fontFamily: '"Helvetica Neue", Poppins_800ExtraBold, Arial, sans-serif',
      fontWeight: '800' as const,
    },
  },
  ios: {
    regular: {
      fontFamily: 'System',
      fontWeight: '400' as const,
    },
    medium: {
      fontFamily: 'System',
      fontWeight: '500' as const,
    },
    light: {
      fontFamily: 'System',
      fontWeight: '300' as const,
    },
    thin: {
      fontFamily: 'System',
      fontWeight: '100' as const,
    },
    // @ts-ignore
    semibold: {
      fontFamily: 'System',
      fontWeight: '600' as const,
    },
    bold: {
      fontFamily: 'System',
      fontWeight: 'bold' as const,
    },
    extrabold: {
      fontFamily: 'System',
      fontWeight: '800' as const,
    },
  },
  android: {
    regular: {
      fontFamily: 'Poppins_400Regular',
      fontWeight: '400' as const,
    },
    medium: {
      fontFamily: 'Poppins_500Medium',
      fontWeight: '500' as const,
    },
    light: {
      fontFamily: 'Poppins_300Light',
      fontWeight: '300' as const,
    },
    thin: {
      fontFamily: 'Poppins_100Thin',
      fontWeight: '100' as const,
    },
    // @ts-ignore
    semibold: {
      fontFamily: 'Poppins_600SemiBold',
      fontWeight: '600' as const,
    },
    bold: {
      fontFamily: 'Poppins_700Bold',
      fontWeight: 'bold' as const,
    },
    extrabold: {
      fontFamily: 'Poppins_800ExtraBold',
      fontWeight: '800' as const,
    },
  },
  default: {
    regular: {
      fontFamily:
        '"Helvetica Neue", Poppins_400Regular, Arial, Roboto, sans-serif',
      fontWeight: '400' as const,
    },
    medium: {
      fontFamily:
        '"Helvetica Neue", Poppins_500Medium, Arial, Roboto, sans-serif-medium',
      fontWeight: '500' as const,
    },
    light: {
      fontFamily:
        '"Helvetica Neue", Poppins_300Light, Arial, Roboto, sans-serif-light',
      fontWeight: '300' as const,
    },
    thin: {
      fontFamily:
        '"Helvetica Neue", Poppins_100Thin, Arial, Roboto, sans-serif-thin',
      fontWeight: '100' as const,
    },
    // @ts-ignore
    semibold: {
      fontFamily:
        '"Helvetica Neue", Poppins_600SemiBold, Arial, Roboto, sans-serif',
      fontWeight: '600' as const,
    },
    bold: {
      fontFamily:
        '"Helvetica Neue", Poppins_700Bold, Arial, Roboto, sans-serif',
      fontWeight: 'bold' as const,
    },
    extrabold: {
      fontFamily:
        '"Helvetica Neue", Poppins_800ExtraBold, Arial, Roboto, sans-serif',
      fontWeight: '800' as const,
    },
  },
};
*/

// export const BASE_FONTS = Platform.select(FONTS);
export const BASE_THEME: MD3Theme = {
  ...MD3LightTheme,
  colors: {
    ...MD3LightTheme.colors,
    primary: PRIMARY_LIGHT,
    secondary: PASTEL_BLUE,
    // background: '#F3E6FD',
    // surface: '#F7EDFE',
  },
};

export const DARK_THEME: MD3Theme = {
  ...MD3DarkTheme,
  colors: {
    ...MD3DarkTheme.colors,
    primary: PRIMARY_LIGHT,
    secondary: PASTEL_BLUE,
    surface: DARK_PURPLE,
    background: BACKGROUND_DARK,
  },
  // fonts: configureFonts(FONTS),
};

export const NAVIGATION_LIGHT_THEME = adaptNavigationTheme({
  reactNavigationLight: NavLightTheme,
  materialLight: BASE_THEME,
}).LightTheme;

export const NAVIGATION_DARK_THEME = adaptNavigationTheme({
  reactNavigationDark: NavDarkTheme,
  materialDark: DARK_THEME,
}).DarkTheme;
