import {
  FetchMediaError,
  fetchMediaWrapped,
  NoResponseContentType,
} from 'fetch-media';
import { useEffect, useRef } from 'react';
import { Alert } from 'react-native';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import type { ReadonlyDeep } from 'type-fest';
import { useLocale } from '../hooks/useLocale';
import { useToken } from '../hooks/useToken';
import { authorization } from '../utils/authorization';

export type ApiEventProfilerTracks = ReadonlyDeep<{
  tracks: {
    _links: {
      self: {
        href: string;
      };
    };

    progress: number;
    total_count: number;

    _embedded: readonly ApiEventTrack[];
  };
}>;

export type ApiEventTracks = ReadonlyDeep<{
  tracks: {
    _links: {
      self: {
        href: string;
        cursor: string;
      };
      next?: {
        href: string;
        cursor: string;
      };
    };

    progress?: number;
    total_count?: number;

    _embedded: readonly ApiEventTrack[];
  };
}>;

export type ApiEventTrack = ReadonlyDeep<{
  track: {
    _links: {
      self: {
        href: string;
      };

      artist?: {
        href: string;
        name: string;
      };

      cover_image?: {
        href: string;
        templated: true;
      };

      wave?: {
        href: string;
        duration: number;
        templated: true;
      };

      stream?: {
        href: string;
        duration: number;
        templated: true;
      };

      vote: {
        href: string;
      };
    };

    name: string;

    snippet_start?: number;
    snippet_end?: number;
  };
}>;

export type ApiTrackCoverImageVariants =
  | 'size_1x'
  | 'size_2x'
  | 'size_3x'
  | 'size_4x';

export type ApiTrackWaveVariants = '96b' | '128b' | '160b' | '192b';
export type ApiTrackStreamVariants = '160b-1080:1920';

export function useEventTracks(
  url: string | null | undefined,
  {
    enabled = true,
    onError,
    ...options
  }: UseQueryOptions<
    ApiEventTracks | { profiler: { href: string } } | { verified: false },
    FetchMediaError | Error
  > = {}
) {
  const urlRef = useRef(url);
  const { ref, token, logout } = useToken();
  const locale = useLocale();

  useEffect(() => {
    urlRef.current = url;
    // Alert.alert(`tracks url: ${url}`);
  }, [url]);

  return useQuery<
    ApiEventTracks | { profiler: { href: string } } | { verified: false },
    FetchMediaError | Error
  >(
    ['tracks', locale, url] as QueryKey,
    async ({ signal }) => {
      if (!ref.current) {
        throw new Error('Expected logged in, actual: no token');
      }

      try {
        const result = await fetchMediaWrapped(urlRef.current!, {
          headers: {
            accept: [
              'application/vnd.soundersmusic.event-profiler.v2+json',
              'application/vnd.soundersmusic.event-track.v2.collection+json',
              'application/vnd.soundersmusic.shared-track.v2.collection+json',
            ].join(', '),
            acceptLanguage: locale,
            authorization: authorization(ref.current)!,
          },
          method: 'GET',
          disableFormData: true,
          disableFormUrlEncoded: true,
          signal,
        });

        if (!result.ok()) {
          throw result.unwrap();
        }

        if (result.response.status === 204) {
          const link = result.response.headers.get('Link');
          if (!link) {
            throw new Error(
              'No tracks received, no research / profiling link received'
            );
          }

          const validLink = link.split(',').find((link) =>
            link
              .split(';')
              .slice(1)
              .some(
                (param) =>
                  param.trim().startsWith('rel=') && param.includes('profiler')
              )
          );
          if (!validLink) {
            throw new Error(
              'No tracks received, no research / profiling link received'
            );
          }

          const href = validLink.split(';')[0].slice(1, -1);
          return { profiler: { href } };
        }

        const contentType = result.response.headers.get('Content-type');
        if (!contentType) {
          throw new Error('Expected Content-Type to be set');
        }

        const body = result.unwrap();

        if (typeof body === 'string' || !body) {
          throw new Error(`Expected tracks, but got a ${typeof body}`);
        }

        if (
          typeof body === 'object' &&
          !Object.prototype.hasOwnProperty.call(body, 'tracks') &&
          !Object.prototype.hasOwnProperty.call(body, 'profiler')
        ) {
          throw new Error(
            `Expected tracks, but got ${Object.keys(body).join(', ')}`
          );
        }

        const [mediaType] = contentType.split(';');
        switch (mediaType) {
          case 'application/vnd.soundersmusic.event-profiler.v2+json': {
            return {
              tracks: (body as { profiler: ApiEventTracks['tracks'] }).profiler,
            } as ApiEventTracks;
          }
          case 'application/vnd.soundersmusic.shared-track.v2.collection+json':
          case 'application/vnd.soundersmusic.event-track.v2.collection+json': {
            return body as ApiEventTracks;
          }
          default: {
            throw new Error(
              `Expected supported media type, actual: ${mediaType}`
            );
          }
        }
      } catch (error) {
        if (
          error instanceof NoResponseContentType &&
          error.response.status === 204
        ) {
          const result = error.response as Response;

          const link = result.headers.get('Link');
          if (!link) {
            throw new Error(
              'No tracks received, no research / profiling link received (no link)'
            );
          }

          const validLink = link.split(',').find((link) =>
            link
              .split(';')
              .slice(1)
              .some(
                (param) =>
                  param.trim().startsWith('rel=') && param.includes('profiler')
              )
          );
          if (!validLink) {
            throw new Error(
              `No tracks received, no research / profiling link received (invalid: ${link.split(
                ','
              )})`
            );
          }

          const href = validLink.split(';')[0].slice(1, -1);
          return { profiler: { href } };
        }

        if (error instanceof FetchMediaError && error.response.status === 403) {
          return { verified: false };
        }

        throw error;
      }
    },
    {
      enabled: Boolean(url && token && enabled),

      async onError(error) {
        console.error({ error });
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            await logout();
          }
        }

        if (onError) {
          onError(error);
        }
      },

      ...options,
    }
  );
}
